const classes = {
  section: {
    paddingBottom: '4rem',
    paddingLeft:   '1.25rem',
    paddingRight:  '1.25rem',
  },
  gridBox: {
    display: 'grid',
    rowGap:  '2rem',
  },
  cardBox: {
    display:    'flex',
    background: '#fff',
  },

  cardText: {
    display:       'flex',
    flexDirection: 'column',
    background:    '#fff',
  },
  typeH4: {
    fontWeight: 800,
    margin:     '0 15px 10px 15px',
    '&:hover':  {
      cursor: 'pointer',
    },
  },
  Text: {
    padding:   '5px',
    '&:hover': {
      cursor:         'pointer',
      textDecoration: 'underline',
    },
  },
  icon: {
    width:        20,
    height:       20,
    marginRight:  '0.5rem',
    marginBottom: '-0.2rem',
  },
}

export default classes
