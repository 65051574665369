// React, Next
import {
  ReactNode, useContext, useEffect, useState,
} from 'react'
import Image from 'next/image'

// MUI
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

// Custom
import { StaticImport } from 'next/dist/shared/lib/get-img-props'
import { GlobalContext } from 'context/GlobalContextProvider'
import breakpoints from 'assets/theme/breakpoints'
import themeColors from '@/assets/theme/colors'
import AlignedText from 'components/alignedText/AlignedText'

// Style
import classes from './CardSection2-CSS'

type AlignedTextProps = {
  text     : string
  icon    ?: ReactNode
  boldText?: string
};
type PropTypes = {
  title       ?: string
  titleColor   : string
  alignedTexts : AlignedTextProps[]
  textColor    : string
  img         ?: string | StaticImport
  alt         ?: string
  background  ?: string
};
export default function CardSection2(props: PropTypes) {
  const [mobileMode, setMobileMode] = useState(false)
  const { screenDimensions } = useContext(GlobalContext)
  useEffect(() => { setMobileMode(screenDimensions.width <= breakpoints.desktopToMobile) }, [screenDimensions])

  const getFontH1 = () => {
    const w = screenDimensions.width
    if (w > breakpoints.desktopLarge) return '1.5rem'
    if (w > breakpoints.desktopSmall) return '1.5rem'
    if (w > breakpoints.mobileMid) return '1.3rem'
    if (w > breakpoints.mobileSmall) return '1.2rem'
    return '1.1rem'
  }
  const getPaddingLeft = () => {
    const w = screenDimensions.width
    if (w > breakpoints.desktopMid) return `${screenDimensions.width / 15}px`
    if (w > breakpoints.desktopSmall) return `${screenDimensions.width / 20}px`
    if (w > breakpoints.desktopToMobile) return `${screenDimensions.width / 25}px`
    return '10px'
  }

  const getPaddingRight = () => {
    const w = screenDimensions.width
    if (w > breakpoints.desktopMid) return `${screenDimensions.width / 18}px`
    if (w > breakpoints.desktopSmall) return `${screenDimensions.width / 28}px`
    if (w > breakpoints.desktopToMobile) return `${screenDimensions.width / 36}px`
    return '10px'
  }

  const getGrid = () => {
    const w = screenDimensions.width
    if (w > breakpoints.desktopSmall) return 'span 4'
    if (w > breakpoints.mobileLarge) return 'span 6'
    return 'span 12'
  }

  return (
    <Box
      sx = {classes.section2}
      gridTemplateColumns = "repeat(12, 1fr)"
    >
      <Box sx = {classes.titleTypo}>
        <Typography
          variant = "h1"
          sx = {{
            ...classes.titleTypo,
            color:    props.titleColor,
            fontSize: getFontH1(),
          }}
        >
          {props.title}
        </Typography>
      </Box>
      {props.img && (
        <Box
          sx = {{
            paddingLeft:  getPaddingLeft(),
            paddingRight: getPaddingRight(),
            ...classes.imgBox,
          }}
        >
          <Image
            alt      = {props.alt ?? ''}
            src      = {props.img}
            style    = {classes.img}
            priority = {false}
          />
        </Box>
      )}
      <Box
        sx                  = {classes.section2}
        gridTemplateColumns = "repeat(12, 1fr)"
        rowGap              = {2}
        columnGap           = {2}
        style               = {{
          display: 'grid',
        }}
      >
        {props.alignedTexts.map((item, index) => (
          <Box
            key        = {`card${index}`}
            gridColumn = {getGrid()}
            sx         = {{
              ...classes.alignedText,
              background: props.background ?? themeColors.info.lightest,
            }}
          >
            <AlignedText
              key         = {index}
              icon        = {item.icon}
              boldText    = {item.boldText}
              description = {item.text}
              textColor   = {props.textColor}
            />

          </Box>
        ))}
      </Box>
    </Box>
  )
}
