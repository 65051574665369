const classes = {
  mainBox: {
    display:        'flex',
    justifyContent: 'center',
    alignItems:     'center',
  },
  linkBox: {
    textDecoration: 'none',
    color:          '#fff',
    '&:hover':      {
      opacity: 0.7,
    },
    '&:active': {
      opacity: 0.85,
    },
  },
  separator: {
    fontSize:    11,
    fontWeight:  400,
    marginLeft:  '5px',
    marginRight: '5px',

    // height:      23,
  },
}

export default classes
