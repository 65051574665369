// Account base route

// export const BASE_URL = 'http://www.kroemtech.com:3221/api/v1'
export const BASE_URL = 'https://www.kroemtech.com/api/v1'

// export const BASE_URL = 'http://localhost:3000/api/v1'

export interface ApiResponse {
  ok: boolean,
  statusCode: number,
  statusMessage: string,
  message: string | null
}
