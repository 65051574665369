// React, Next
import { useEffect, useState } from 'react'
import { getCookie, setCookie } from 'cookies-next'
import { useRouter } from 'next/router'

// Custom
import MainFooter from 'components/footers/main/MainFooter'
import AboutView from 'components/views/about/AboutView'
import IndexView from 'components/views/index/IndexView'
import ContactView from 'components/views/contact/ContactView'
import ServicesView from 'components/views/services/ServicesView'
import MainHeader from 'components/headers/main/MainHeader'
import PrivacyView from 'components/views/privacy/PrivacyView'
import TermsView from 'components/views/terms/TermsView'
import ArticlesView from 'components/views/articles/ArticlesView'
import ApplicationsView from 'components/views/applications/ApplicationsView'
import GpdrModal from 'components/modals/GpdrModal'
import ArticleTemplate from 'components/views/articles/posts/ArticleTemplate'
import DathascyView from '@/components/views/software/views/dathascy/DathascyView'

// Types
import { View } from 'types/global_types'

// Config
import nextI18nextConfig from 'next-i18next.config'

// Style
import classes from './MainLayout-CSS'
import TechnologyView from '../views/technology/TechnologyView'

type Props= {
  view: View,
  isArticle?:boolean
}

export default function MainLayout(props:Props) {

  // reload page on language change
  const router = useRouter()
  const currentLocale = router.query.locale || nextI18nextConfig.i18n.defaultLocale
  const [prevLocale, setPrevLocale] = useState(currentLocale)
  useEffect(() => {
    if (prevLocale !== currentLocale) {
      window.location.reload()
      setPrevLocale(currentLocale)
    }
  }, [currentLocale])

  const getView = () => {
    switch (props.view) {
      case 'index': return <IndexView />
      case 'technology': return <TechnologyView />
      case 'services': return <ServicesView />
      case 'applications': return <ApplicationsView />

      case 'about': return <AboutView />
      case 'contact': return <ContactView />
      case 'privacy': return <PrivacyView />
      case 'articles': return <ArticlesView />
    }
    if (props.isArticle) return <ArticleTemplate article = {props.view} />
    return null
  }

  // GPDR
  const [showGpdr, setShowGpdr] = useState(false)

  useEffect(() => {
    const gpdr = getCookie('kroemtechGpdr')
    setShowGpdr(gpdr !== 'true')
  }, [])

  const onAccept = () => {
    setCookie('kroemtechGpdr', 'true')
    setShowGpdr(false)
  }

  return (
    <>
      <GpdrModal
        show     = {showGpdr}
        onAccept = {() => onAccept()}
      />
      <div style = {classes.main}>

        <MainHeader />

        {getView()}

        <div>
          <MainFooter />
        </div>

      </div>
    </>
  )
}
