import themeColors from '@/assets/theme/colors'

const classes = {
  main: {
    background: themeColors.kroemtech.background,
  },

}

export default classes
