// React
import { ReactElement } from 'react'
import Link from 'next/link'

// Mui
import Box from '@mui/material/Box'
import Zoom from '@mui/material/Zoom'
import Tooltip from '@mui/material/Tooltip'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import FacebookIcon from '@mui/icons-material/Facebook'
import InstagramIcon from '@mui/icons-material/Instagram'
import PinterestIcon from '@mui/icons-material/Pinterest'

// Types
import { TooltipPlacement } from 'types/global_types'

// Style
import classes from './SocialMediaIconButton-CSS'

type Props = {
  socialMedia      : 'facebook'|'instagram'|'linkedin'|'pinterest',
  href             : string,
  color           ?: string,
  tooltip         ?: string|ReactElement
  tooltipPlacement?: TooltipPlacement
  marginTop       ?: number,
  marginBottom    ?: number,
  marginLeft      ?: number,
  marginRight     ?: number
  scale           ?: number
}

export default function SocialMediaIconButton(props:Props) {

  const getIcon = () => {
    switch (props.socialMedia) {
      case 'facebook':
        return (
          <FacebookIcon
            sx = {classes.iconClass}
            style = {{
              color:     props.color,
              transform: `scale(${props.scale})`,
            }}
          />
        )

      case 'pinterest':
        return (
          <PinterestIcon
            sx = {classes.iconClass}
            style = {{
              color:     props.color,
              transform: `scale(${props.scale})`,
            }}
          />
        )

      case 'linkedin':
        return (
          <LinkedInIcon
            sx = {classes.iconClass}
            style = {{
              color:     props.color,
              transform: `scale(${props.scale})`,
            }}
          />
        )

      case 'instagram':
        return (
          <InstagramIcon
            sx = {classes.iconClass}
            style = {{
              color:     props.color,
              transform: `scale(${props.scale})`,
            }}
          />
        )
    }
  }

  return (

    <Tooltip
      TransitionComponent = {Zoom}
      arrow
      title           = {props.tooltip === undefined ? undefined : <div style = {{ fontSize: 14 }}>{props.tooltip}</div>}
      placement       = {props.tooltipPlacement === undefined ? 'bottom' : props.tooltipPlacement}
      enterDelay      = {300}
    >
      <Box
        sx = {classes.mainBox}
        style = {{
          color:        props.color,
          marginTop:    props.marginTop,
          marginBottom: props.marginBottom,
          marginLeft:   props.marginLeft,
          marginRight:  props.marginRight,
        }}
      >
        <Link
          href      = {props.href}
          target    = "_blank"
          style = {classes.navLink}
        >
          {getIcon()}
        </Link>
      </Box>
    </Tooltip>

  )
}
