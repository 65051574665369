// React, Next
import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import { useContext, useEffect, useState } from 'react'

// MUI
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

// Custom
import { GlobalContext } from 'context/GlobalContextProvider'

// Style
import themeColors from '@/assets/theme/colors'
import breakpoints from '@/assets/theme/breakpoints'
import classes from './WelcomeSection-CSS'

export default function WelcomeSection() {

  const { t } = useTranslation('technology')

  const { screenDimensions } = useContext(GlobalContext)
  const [mobileMode, setMobileMode] = useState(false)

  useEffect(() => { setMobileMode(screenDimensions.width <= breakpoints.desktopToMobile) }, [screenDimensions])

  const getImgHeight = () => {
    const w = screenDimensions.width

    if (w > breakpoints.desktopLarge) return 450
    if (w > breakpoints.mobileLarge) return 350
    if (w > breakpoints.mobileMid) return 350
    if (w > breakpoints.mobileSmall) return 350
    return 350
  }

  const getFontH2 = () => {
    const w = screenDimensions.width
    if (w > breakpoints.desktopLarge) return '2.53rem'
    if (w > breakpoints.desktopSmall) return '2.2rem'
    if (w > breakpoints.mobileMid) return '1.8rem'
    if (w > breakpoints.mobileSmall) return '1.8rem'
    return '1.5rem'
  }

  const getFontH3 = () => {
    const w = screenDimensions.width
    if (w > breakpoints.desktopLarge) return '2rem'
    if (w > breakpoints.desktopSmall) return '1.8rem'
    if (w > breakpoints.mobileMid) return '1.6rem'
    if (w > breakpoints.mobileSmall) return '1.6rem'
    return '1.5rem'
  }

  const getFontB1 = () => {
    const w = screenDimensions.width
    if (w > breakpoints.desktopLarge) return '1.4rem'
    if (w > breakpoints.desktopSmall) return '1.3rem'
    if (w > breakpoints.mobileMid) return '1.2rem'
    if (w > breakpoints.mobileSmall) return '1.2rem'
    return '1.1rem'
  }

  const getSection = () => (
    <Box sx = {classes.subsectionMain}>
      <Box
        sx = {classes.subsection}
      >
        <Typography
          variant  = "h1"
          fontSize = {getFontH2()}
          style    = {{
            marginTop:  '1rem',
            textAlign:  'left',
            lineHeight: 1.2,
            color:      themeColors.gray.darker,
            fontWeight: 'bold',
          }}
        >
          {t('welcome.title')}
        </Typography>

      </Box>
    </Box>
  )

  // *** MAIN RENDER ***
  return (
    <>
      <Box
        component           = "section"
        sx                  = {classes.section}
      >
        {getSection()}
        <Box
          sx = {classes.imgBox}
        >
          <Image
            alt    = "Kroemtech"
            src    = "https://kroemtech-public.s3.eu-central-1.amazonaws.com/kroemtech_website/technology/pcb_to_cloud.webp"
            width  = {screenDimensions.width}
            height = {getImgHeight()}
            style  = {{
              objectPosition: '60% 20%',
              objectFit:      'cover',
            }}
            priority = {false}
          />
        </Box>
      </Box>
      <Box sx = {classes.titleBox}>
        <Typography
          variant  = "body1"
          fontSize = {getFontH3()}
          style    = {{
            color:      themeColors.gray.darker,
            textAlign:  'left',
            lineHeight: 1.2,
            maxWidth:   '700px',
          }}
        >
          {t('welcome.subTitle')}
        </Typography>
        <Typography sx = {{
          color:      themeColors.gray.mid,
          lineHeight: 1.2,
          fontSize:   getFontB1(),
          marginTop:  '1rem',
        }}
        >
          {t('welcome.description')}
        </Typography>

      </Box>
    </>
  )
}
