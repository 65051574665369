// Rect, Next
import { useContext, useState } from 'react'
import { useRouter } from 'next/router'
import Image from 'next/image'
import Link from 'next/link'

// import { useTranslation } from 'next-i18next'

// MUI
import MenuIcon from '@mui/icons-material/Menu'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'

// Img
import KroemtechLogoDark from 'public/img/logos/kroemtech/kroemtech_logo_dark.svg'

// Custom
import breakpoints from 'assets/theme/breakpoints'
import MainMenu from 'components/headers/main/MainMenu'
import LanguageSwitcher from 'components/tools/buttons/LanguageSwitcher/LanguageSwitcher'
import MenuSideBar from 'components/sidebars/MenuSideBar'
import { GlobalContext } from 'context/GlobalContextProvider'

// Config
import i18nextConfig from 'next-i18next.config'

// Style
import classes from './MainHeader-CSS'

export default function MainHeader() {

  const { screenDimensions } = useContext(GlobalContext)
  const router = useRouter()
  const currentLocale = router.query.locale || i18nextConfig.i18n.defaultLocale

  // const { t } = useTranslation('common')

  const [drawerOpen, setDrawerOpen] = useState(false)

  const getLangButtons = (mobileMode:boolean) => (
    <Box
      sx = {{ marginRight: screenDimensions.width <= 1100 && !mobileMode ? '10px' : '50px' }}
    >
      <LanguageSwitcher
        currentLocale = {currentLocale}
      />
    </Box>
  )

  return (
    <>
      <SwipeableDrawer
        anchor  = "right"
        open    = {drawerOpen}
        onClose = {() => setDrawerOpen(false)}
        onOpen  = {() => setDrawerOpen(true)}
      >
        <MenuSideBar
          setDrawerOpen = {(state:boolean) => setDrawerOpen(state)}
        />
      </SwipeableDrawer>

      <AppBar sx = {classes.appbarStyle}>
        <Toolbar disableGutters>
          <Box sx = {classes.mainBox}>
            <Box
              sx    = {classes.logoBox}
              style = {{ marginLeft: screenDimensions.width >= breakpoints.desktopToMobile ? '50px' : '20px' }}
            >
              <Link
                href = "/"
              >
                <Image
                  alt = "Kroemtech"
                  src = {KroemtechLogoDark}
                  priority
                  width  = {screenDimensions.width > breakpoints.mobileSmall ? 169 : 140}
                  height = {screenDimensions.width > breakpoints.mobileSmall ? 30 : 25}
                />
              </Link>
            </Box>
            <Box sx = {classes.linksBox}>
              {
                screenDimensions.width >= breakpoints.desktopToMobile
                  ? (
                    <>
                      <Box
                        sx    = {classes.flexBox}
                        style = {{ marginRight: screenDimensions.width <= 1100 ? '20px' : '60px' }}
                      >
                        <MainMenu
                          locale = {currentLocale}
                        />
                      </Box>
                      {getLangButtons(false)}
                    </>
                  )
                  : (
                    <MenuIcon
                      onClick = {() => setDrawerOpen(true)}
                      sx      = {classes.menuIcon}
                    />
                  )
              }
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
    </>
  )
}

// to add a link to the nav bar but not a dropdown
/*

      {i18nextConfig.i18n.locales.map((locale) => {
        if (locale === currentLocale) return null
        return (
          <LanguageSwitchLink
            locale = {locale}
            key = {locale}
          />
        )
      })}

<Link to = {i18n.language === 'de' ? '/' : `/${i18n.language.slice(0, 2)}`}>
                <img
                  alt       = ""
                  src       = {KroemtechLogoDark}
                  sx = {classes.logo}
                />
              </Link>

<Link
        to = {href}
        sx = {classes.typoLink}
      >
        <Box
          sx = {{ fontSize: mobileMode ? 13 : undefined }}
          onClick = {() => languageDetector.cache(locale)}
        >
          {screenDimensions.width >= 1100 ? 'English' : 'EN'}
        </Box>
      </Link>

      <div sx = {classes.langButton2}>
        <Typography>
          |
        </Typography>
      </div>
      <Link
        to = {href}
        sx = {classes.typoLink}
      >
        <Box
          sx = {{ fontSize: mobileMode ? 13 : undefined }}
          onClick = {() => languageDetector.cache(locale)}
        >
          {screenDimensions.width >= 1100 ? 'Deutsch' : 'DE'}
        </Box>
      </Link>

         <SocialMediaIconButton
                                    socialMedia = "linkedin"
                                  />
                                  <SocialMediaIconButton
                                    socialMedia = "pinterest"
                                  />
                                  <SocialMediaIconButton
                                    socialMedia = "facebook"
                                  />
                                  <SocialMediaIconButton
                                    socialMedia = "instagram"
                                  />

                                  <AdminDropdown />

onClick = {() => changeLanguage('en')}

<MenuSideBar
          setDrawerOpen = {(state:boolean) => setDrawerOpen(state)}
        />
*/
