// React, Next
import { useTranslation } from 'next-i18next'
import { useContext } from 'react'

// MUI
import Box from '@mui/material/Box'
import {
  Typography,
} from '@mui/material'

// Next
import { useRouter } from 'next/router'
import Link from 'next/link'

// Images
import Image from 'next/image'
import DataCollection from 'public/img/articles/data_monitoring_1.webp'

// Translate
import { translateHref } from '@/assets/functions/links'
import nextI18nextConfig from 'next-i18next.config'

// Custom
import { GlobalContext } from 'context/GlobalContextProvider'

// Style
import breakpoints from 'assets/theme/breakpoints'
import classes from './A0003-CSS'

//* Importance of machine data collection
export default function A0003() {

  const { t } = useTranslation('article0003')

  const router = useRouter()
  const currentLocale = router.query.locale || nextI18nextConfig.i18n.defaultLocale

  const { screenDimensions } = useContext(GlobalContext)

  const getItem = (title:string, content:string) => (

    <li>
      <Typography
        variant = "body1"
        sx = {classes.b2}
      >
        <b>{title}</b>
        <br />
        <br />
        {content}
      </Typography>
    </li>

  )

  return (
    <Box>

      <Typography
        variant = "h1"
        sx = {classes.h1}
        style = {{
          fontSize: screenDimensions.width > breakpoints.mobileSmall ? '1.85rem' : '1.55rem',
        }}
      >
        {t('title')}
      </Typography>
      <Box
        style = {{}}
        marginBottom = "25px"
      >
        <Image
          alt = ""
          src = {DataCollection}
          style = {{
            height:         (screenDimensions.width > 800 ? 800 : screenDimensions.width) / 3,
            width:          screenDimensions.width > 800 ? 800 : '100%',
            objectFit:      'cover',
            objectPosition: '50% 0',
          }}
          priority
        />
      </Box>
      <section>
        <Typography
          variant = "body1"
          sx = {classes.b2}
        >
          16.02.2024
        </Typography>
        <Typography
          variant = "body1"
          sx = {classes.b2}
        >
          { t('content.intro.1') }
        </Typography>
        <Typography
          variant = "body1"
          sx = {classes.b2}
        >
          { t('content.intro.2') }
        </Typography>
        <ol
          type = "1"
          style = {{
            ...classes.ml,
            ...classes.b2,
            fontFamily: 'roboto',
            fontWeight: 'bold',
          }}
        >
          {getItem(t('content.items.1.title'), t('content.items.1.content'))}
          {getItem(t('content.items.2.title'), t('content.items.2.content'))}
          {getItem(t('content.items.3.title'), t('content.items.3.content'))}
          {getItem(t('content.items.4.title'), t('content.items.4.content'))}
          {getItem(t('content.items.5.title'), t('content.items.5.content'))}
          {getItem(t('content.items.6.title'), t('content.items.6.content'))}
          {getItem(t('content.items.7.title'), t('content.items.7.content'))}
          {getItem(t('content.items.8.title'), t('content.items.8.content'))}
          {getItem(t('content.items.9.title'), t('content.items.9.content'))}
          {getItem(t('content.items.10.title'), t('content.items.10.content'))}
          {getItem(t('content.items.11.title'), t('content.items.11.content'))}
        </ol>
        <Typography
          variant = "body1"
          sx = {classes.b2}
        >
          { t('content.conclusion') }
        </Typography>
        <Link
          href  = {translateHref(currentLocale, 'contact')}
          style = {classes.typoLink}
        >
          <Typography
            variant = "body1"
            sx = {classes.b3}
          >
            { t('content.hook') }
          </Typography>
        </Link>
      </section>

    </Box>
  )
}
