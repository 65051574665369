// React, Next
import { useTranslation } from 'next-i18next'
import {
  ReactElement,
  useContext, useEffect, useRef, useState,
} from 'react'
import { useRouter } from 'next/router'

// MUI
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import LooksOneIcon from '@mui/icons-material/LooksOne'
import LooksTwoIcon from '@mui/icons-material/LooksTwo'
import Looks3Icon from '@mui/icons-material/Looks3'
import Looks4Icon from '@mui/icons-material/Looks4'

// Custom
import { GlobalContext } from 'context/GlobalContextProvider'
import { translateHref } from 'assets/functions/links'

// Config
import i18nextConfig from 'next-i18next.config'

// Style
import breakpoints from 'assets/theme/breakpoints'
import themeColors from '@/assets/theme/colors'
import classes from './ConseptSection-CSS'

export default function ConseptSection() {

  const { t } = useTranslation('index')
  const { screenDimensions } = useContext(GlobalContext)

  const router = useRouter()
  const currentLocale = router.query.locale || i18nextConfig.i18n.defaultLocale

  const ref1:any = useRef()
  const ref2:any = useRef()
  const ref3:any = useRef()
  const ref4:any = useRef()

  const [mobileMode, setMobileMode] = useState(false)

  useEffect(() => {
    setMobileMode(screenDimensions.width <= breakpoints.desktopToMobile)
  }, [screenDimensions])

  const getFontH3 = () => {
    const w = screenDimensions.width
    if (w > breakpoints.desktopLarge) return '3rem'
    if (w > breakpoints.desktopSmall) return '2rem'
    if (w > breakpoints.desktopToMobile) return '2rem'
    if (w > breakpoints.mobileSmall) return '1.8rem'
    return '1.25rem'
  }

  const getFontB1 = () => {
    const w = screenDimensions.width
    if (w > breakpoints.desktopToMobile) return '1.5rem'
    if (w > breakpoints.mobileLarge) return '1.4rem'
    if (w > breakpoints.mobileMid) return '1.2rem'
    return '1.1rem'
  }

  const getPadding = () => {
    const w = screenDimensions.width
    if (w > breakpoints.desktopMid) return `${screenDimensions.width / 16}px`
    if (w > breakpoints.desktopSmall) return `${screenDimensions.width / 20}px`
    if (w > breakpoints.desktopToMobile) return `${screenDimensions.width / 30}px`
    return '0px'
  }

  const getGrid4 = () => {
    const w = screenDimensions.width
    if (w > breakpoints.desktopMid) return 'span 3'
    if (w > breakpoints.desktopToMobile) return 'span 6'
    return 'span 12'
  }

  const getCardWidth = () => {
    const w = screenDimensions.width
    if (getGrid4() === 'span 12') {
      return {
        max: w - 40,
        set: w - 40,
        min: w - 40,
      }
    }
    return { max: 600, set: undefined, min: 300 }
  }

  const getSectionMarginTop = () => {
    const w = screenDimensions.width
    if (w > breakpoints.desktopLarge) return '10rem'
    if (w > breakpoints.desktopMid) return '8rem'
    if (w > breakpoints.desktopSmall) return '7rem'
    if (w > breakpoints.desktopToMobile) return '6rem'
    return '0rem'
  }

  // *** Section ***
  const getCard = (p:{
    ndx            : number,
    ref            : any,
    title          : string,
    subtitle       : string,
    alt            : string,
    href           : string,
    objectPosition?: string
    icon           : ReactElement
  }) => (
    <Box
      gridColumn = {getGrid4()}
      sx = {{
        maxWidth: getCardWidth().max,
        minWidth: getCardWidth().min,
        width:    getCardWidth().set,
        margin:   !mobileMode ? '10px' : '10px 0 10px 0',
      }}
      style = {classes.card}
    >
      <Typography
        variant = "body1"
        fontSize = {getFontB1()}
        style = {classes.typoB1}
      >
        {p.icon}
        <b>{p.title}</b>
        {' '}
        {p.subtitle}
      </Typography>
    </Box>
  )

  const getSection = () => (
    <>
      <Box
        style = {{
          zIndex:      2,
          padding:     '1rem 1rem 1rem 10vw',
          marginLeft:  '-1rem',
          marginRight: '-1rem',
          background:  themeColors.gray.lightest,
        }}
      >
        <Typography
          variant = "h3"
          sx = {{
            ...classes.typoTitle,
            textAlign:    'left',
            fontWeight:   400,
            paddingLeft:  getPadding(),
            paddingRight: getPadding(),
            fontSize:     getFontH3(),
          }}
        >
          {t('consept.title')}
        </Typography>
      </Box>
      <Box
        gridTemplateColumns = "repeat(12, 1fr)"
        sx = {classes.cardBox}
        style = {{
          paddingLeft:  getPadding(),
          paddingRight: getPadding(),
        }}
      >
        {
            getCard({
              ndx:      1,
              ref:      ref1,
              title:    t('consept.subtitleBold1'),
              subtitle: t('consept.subtitle1'),
              alt:      'machine development',
              href:     translateHref(currentLocale, 'engineering'),
              icon:     <LooksOneIcon sx = {classes.numIcon} />,
            })
          }
        {
            getCard({
              ndx:            2,
              ref:            ref2,
              title:          t('consept.subtitleBold2'),
              subtitle:       t('consept.subtitle2'),
              alt:            'data collection',
              href:           translateHref(currentLocale, 'software-development'),
              objectPosition: '50% 0%',
              icon:           <LooksTwoIcon sx = {classes.numIcon} />,
            })
          }
        {
            getCard({
              ndx:      3,
              ref:      ref3,
              title:    t('consept.subtitleBold3'),
              subtitle: t('consept.subtitle3'),
              alt:      'control system',
              href:     translateHref(currentLocale, 'software-development'),
              icon:     <Looks3Icon sx = {classes.numIcon} />,
            })
          }
        {
            getCard({
              ndx:      4,
              ref:      ref4,
              title:    t('consept.subtitleBold4'),
              subtitle: t('consept.subtitle4'),
              alt:      'web app',
              href:     translateHref(currentLocale, 'software-development'),
              icon:     <Looks4Icon sx = {classes.numIcon} />,
            })
          }
      </Box>
    </>
  )

  // *** MAIN RENDER ***
  return (

    <Box
      component = "section"
      style = {{
        ...classes.section,
        marginTop: getSectionMarginTop(),
      }}
    >
      {getSection()}
    </Box>

  )
}
