const classes = {
  section: {
    display:        'grid',
    justifyContent: 'center',
    paddingTop:     '4.375rem',
    paddingBottom:  '1.25rem',
  },
  subsection: {
    display:    'flex',
    alignItems: 'center',
  },
  subsectionDiv: {
    zIndex: 2,
  },
  icon: {
    width:        20,
    height:       20,
    marginRight:  '0.5rem',
    marginBottom: '-0.2rem',
  },
  titleTypo: {
    marginBottom: '1.875rem',
    fontWeight:   600,
  },
}

export default classes
