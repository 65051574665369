// React, Next
import { useTranslation } from 'next-i18next'
import { useContext } from 'react'

// MUI
import Box from '@mui/material/Box'
import { Typography } from '@mui/material'

// Custom
import { GlobalContext } from 'context/GlobalContextProvider'

// Style
import breakpoints from 'assets/theme/breakpoints'
import classes from './AboutView-CSS'

export default function AboutView() {

  const { t } = useTranslation('about')

  const { screenDimensions } = useContext(GlobalContext)

  return (
    <Box
      sx = {classes.mainDiv}
      style = {{
        marginTop: screenDimensions.width > breakpoints.desktopToMobile ? '7rem' : '3rem',
      }}
    >
      <Box
        sx = {classes.sndDiv}
        style = {{
          minHeight: screenDimensions.height - 220,
        }}
      >
        <Typography
          variant = "h1"
          sx = {classes.h1}
          style = {{
            fontSize: screenDimensions.width > breakpoints.mobileSmall ? '2.125rem' : '1.875rem',
          }}
        >
          {t('h1')}
        </Typography>
        <section>
          <Typography
            variant = "h2"
            sx = {classes.h2}
            style = {{
              fontSize: screenDimensions.width > breakpoints.mobileSmall ? '1.5rem' : '1.375rem',
            }}
          >
            {t('whoWeAreH2')}
          </Typography>
          <Typography
            variant = "body1"
            sx = {classes.b1}
          >
            {t('whoWeAreB')}
          </Typography>
        </section>
        <section>
          <Typography
            variant = "h2"
            sx = {classes.h2}
            style = {{
              fontSize: screenDimensions.width > breakpoints.mobileSmall ? '1.5rem' : '1.375rem',
            }}
          >
            {t('teamH2')}
          </Typography>
          <Typography
            variant = "body1"
            sx = {classes.b1}
          >
            {t('teamB')}
          </Typography>
        </section>
        <section>
          <Typography
            variant = "h2"
            sx = {classes.h2}
            style = {{
              fontSize: screenDimensions.width > breakpoints.mobileSmall ? '1.5rem' : '1.375rem',
            }}
          >
            {t('whatWeDoH2')}
          </Typography>
          <Typography
            variant = "body1"
            sx = {classes.b1}
          >
            {t('whatWeDoB')}
          </Typography>
        </section>
        <section>
          <Typography
            variant = "h2"
            sx = {classes.h2}
            style = {{
              fontSize: screenDimensions.width > breakpoints.mobileSmall ? '1.5rem' : '1.375rem',
            }}
          >
            {t('ourMissionH2')}
          </Typography>
          <Typography
            variant = "body1"
            sx = {classes.b2}
          >
            {t('ourMissionB')}
          </Typography>
        </section>
        <section>
          <Typography
            variant = "h2"
            sx = {classes.h2}
            style = {{
              fontSize: screenDimensions.width > breakpoints.mobileSmall ? '1.5rem' : '1.375rem',
            }}
          >
            {t('joinUsH2')}
          </Typography>
          <Typography
            variant = "body1"
            sx = {classes.b2}
          >
            {t('joinUsB')}
          </Typography>
        </section>
      </Box>
    </Box>
  )
}
