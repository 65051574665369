import postalCodeValidation from 'postal-codes-js'

const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance()

// return true if a string can be a valid email
export const isEmail = (email:string|null|undefined) => {

  // email validation regex
  const regEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  if (email !== null && email !== undefined) if (regEmail.test(email)) return true
  return false
}

// return true if input is a valid person's name
export const isName = (name:string|null|undefined) => {

  // person's name regex
  const regName = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u
  const onlySpaces = /^\s*$/
  if (name !== null && name !== undefined) if (regName.test(name)) if (!onlySpaces.test(name)) return true
  return false
}

// return true if input is a possible address
export const isAddress = (address:string|null|undefined, maxLineBreaks?:number) => {
  let maxBreaks = 0
  if (maxLineBreaks !== undefined) maxBreaks = maxLineBreaks
  let lineBreaks = 0

  // address regex
  const regAddress = /^[a-zA-Z0-9àáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-(\r\n|\r|\n)]+$/u
  if (address !== null && address !== undefined) {
    for (let i = 0; i < address.length; i += 1) if (address.charCodeAt(i) === 10) lineBreaks += 1
    if (regAddress.test(address) && lineBreaks <= maxBreaks) return true
  }
  return false
}

// return true if input is a possible company name
export const isCompanyName = (address:string|null|undefined) => {

  // address regex
  const regAddress = /^[^*=+#%][a-zA-Z0-9àáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-/&@$£€¥;:*=+#%]+$/u
  if (address !== null && address !== undefined) if (regAddress.test(address)) return true
  return false
}

// return true if input is a valid postal code for the assigned country
// country code must be like: US, BR, CH
export const isPostalCode = (countryCode:string, postalCode:string) => {
  try {
    if (postalCodeValidation.validate(countryCode, postalCode) === true) return true                                     // returns string with error message if validation fails
    return false
  }
  catch (e) { /* empty */ }
  return false
}

// return true if input is a valid phone number for the assigned country
// country code must be like: US, BR, CH
export const isPhoneNumber = (countryCode:string, phone:string) => {
  try {
    return phoneUtil.isValidNumberForRegion(phoneUtil.parse(phone, countryCode), countryCode)
  }
  catch (e) { /* empty */ }
  return false
}
