const breakpoints = {
  desktopLarge:    1600,
  desktopMid:      1350,
  desktopSmall:    1100,
  desktopToMobile: 1000, // width in which the layout changes from desktop to mobile
  mobileLarge:     750,
  mobileMid:       600,
  mobileSmall:     450,
  mobileSmaller:   320,
}

export default breakpoints
