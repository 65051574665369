// React, Next
import { useTranslation } from 'next-i18next'
import {
  useContext, useEffect, useRef, useState,
} from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'

// MUI
import FadeIn from 'react-fade-in'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Button } from '@mui/material'
import Image from 'next/image'
import i18nextConfig from 'next-i18next.config'

// Custom
import { GlobalContext } from 'context/GlobalContextProvider'
import { translateHref } from 'assets/functions/links'
import PhotoCarrousel from 'components/carrousel/PhotoCarrousel'

// Style
import breakpoints from 'assets/theme/breakpoints'
import classes from './HeroSection2-CSS'

export default function HeroSection2() {

  const { t } = useTranslation('index')
  const router = useRouter()
  const currentLocale = router.query.locale || i18nextConfig.i18n.defaultLocale

  const Ref:any = useRef()

  const { screenDimensions } = useContext(GlobalContext)
  const [mobileMode, setMobileMode] = useState(false)

  const [animationTimer, setAnimationTimer] = useState(80)
  const [brightness, setBrightness] = useState(100)

  const [currentSlide, setCurrentSlide] = useState(0)
  const [slideDelay, setSlideDelay] = useState(3000)
  const [viewPointW, setViewPointW] = useState(0)
  const [viewPointWDir, setViewPointWDir] = useState(false) // true = right to left

  useEffect(() => {

    // exit early when we reach 0
    if (!animationTimer) return

    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setAnimationTimer(animationTimer - 1)
      if (animationTimer <= 60 && brightness > 50) setBrightness(brightness - 1)
    }, 50)

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId)

    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [animationTimer, brightness])

  useEffect(() => { setMobileMode(screenDimensions.width <= breakpoints.desktopToMobile) }, [screenDimensions])

  const getFontH1 = () => {
    const w = screenDimensions.width
    if (w > breakpoints.desktopLarge) return '3rem'
    if (w > breakpoints.desktopSmall) return '2.5rem'
    if (w > breakpoints.mobileMid) return '2rem'
    if (w > breakpoints.mobileSmall) return '1.875rem'
    return '1.625rem'
  }

  const getFontH2 = () => {
    const w = screenDimensions.width
    if (w > breakpoints.desktopLarge) return '2rem'
    if (w > breakpoints.desktopToMobile) return '1.75rem'
    if (w > breakpoints.mobileLarge) return '1.5rem'
    return '1.35rem'
  }

  const getFontBody = () => {
    const w = screenDimensions.width
    if (w > breakpoints.desktopLarge) return '1.1rem'
    if (w > breakpoints.desktopToMobile) return '1rem'
    if (w > breakpoints.mobileLarge) return '0.9rem'
    return '0.85rem'
  }

  const getPadding = () => {
    const w = screenDimensions.width
    if (w > breakpoints.desktopMid) return `0 0 0 ${screenDimensions.width / 100}rem`
    if (w > breakpoints.desktopSmall) return `0 0 0 ${screenDimensions.width / 120}rem`
    if (w > breakpoints.desktopToMobile) return `0 0 0 ${screenDimensions.width / 200}rem`
    return '0 0.75rem 0 0.75rem'
  }

  useEffect(() => {
    setTimeout(() => setSlideDelay(1000), 1000)
  }, [])

  useEffect(() => {
    const intervalId = setInterval(() => {
      const min = 20
      const max = 80
      const step = 0.1
      let w = viewPointW
      if (w <= min) {
        setViewPointWDir(false)
        w = min + step
      }
      else if (w >= max) {
        setViewPointWDir(true)
        w = max - step
      }
      else if (viewPointWDir) w -= step
      else w += step
      setViewPointW(w)
    }, 20)

    return () => clearInterval(intervalId)
  }, [viewPointW, viewPointWDir])

  // get if video is on the active screen
  const videoRef = useRef(null)
  const [isVideoVisible, setIsVideoVisible] = useState(false)

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVideoVisible(true)
            observer.disconnect()
          }
        })
      },
      {
        threshold: 0.25, // Adjust the threshold as needed
      },
    )

    if (videoRef.current) {
      observer.observe(videoRef.current)
    }

    return () => {
      if (observer && videoRef.current) {
        observer.unobserve(videoRef.current)
      }
    }
  }, [])

  const getVideoLink = () => {
    const h = screenDimensions.height
    if (h > 720) return 'https://kroemtech-public.s3.eu-central-1.amazonaws.com/kroemtech_website/index/iot_user_w1920.mp4'
    if (h > 576) return 'https://kroemtech-public.s3.eu-central-1.amazonaws.com/kroemtech_website/index/iot_user_w1280.mp4'
    return 'https://kroemtech-public.s3.eu-central-1.amazonaws.com/kroemtech_website/index/iot_user_w720.mp4'
  }

  const playVideo = () => (
    <div
      style = {{
        zIndex: 5,
      }}
    >
      <video
        ref = {videoRef}
        playsInline
        muted
        loop
        autoPlay
        poster = "https://kroemtech-public.s3.eu-central-1.amazonaws.com/kroemtech_website/index/iot_user_poster.webp"
        width  = {screenDimensions.width}
        height = {screenDimensions.height}
        style  = {{
          boxShadow:      'rgba(0, 0, 0, 0.15) 0px 5px 15px 0px',
          marginBottom:   !mobileMode ? 0 : '2rem',
          filter:         `brightness(${brightness.toFixed(0)}%)`,
          objectFit:      'cover',
          objectPosition: '52% 50%',
        }}
      >
        {isVideoVisible && (
          <source
            type = "video/mp4"
            src = {getVideoLink()}
          />
        )}
        Your browser does not support the video tag.
      </video>
    </div>
  )

  const h2Text = (slide:number, text:string) => {
    if (currentSlide === slide) {
      return (
        <Typography
          variant = "h2"
          fontSize = {getFontH2()}
          style = {{
            textAlign:  !mobileMode ? 'left' : 'center',
            lineHeight: 1.5,
            color:      '#fff',
          }}
        >
          <FadeIn
            delay = {slideDelay}
            transitionDuration = {300}
            visible
          >
            {text}
          </FadeIn>
        </Typography>
      )
    }
  }

  const getH2s = () => (
    <Box
      style = {{
        height: '15vh',
      }}
    >
      <>
        {h2Text(0, t('hero.h2.1'))}
        {h2Text(1, t('hero.h2.2'))}
        {h2Text(2, t('hero.h2.3'))}
        {h2Text(3, t('hero.h2.4'))}
        {h2Text(4, t('hero.h2.5'))}
      </>
    </Box>
  )

  // *** SECTION ***
  const getSection = () => (
    <Box
      sx = {{
        ...classes.subsection,
        justifyContent: !mobileMode ? 'left' : 'center',
      }}
    >
      <Box style = {{
        position: 'absolute',
        top:      40,
        left:     0,
      }}
      >
        <PhotoCarrousel
          ref               = {Ref}
          setCurrentSlideNo = {(ndx:number) => setCurrentSlide(ndx)}
          slides            = {[
            playVideo(),
            <Image
              alt    = "industrial sensors"
              src    = "https://kroemtech-public.s3.eu-central-1.amazonaws.com/kroemtech_website/index/industrial_sensors.webp"
              width  = {screenDimensions.width}
              height = {screenDimensions.height}
              style  = {{
                boxShadow:      !mobileMode ? 'rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px' : undefined,
                filter:         `brightness(${brightness.toFixed(0)}%)`,
                objectFit:      'cover',
                objectPosition: `${viewPointW}% ${viewPointW}%`,
                transition:     'height 300ms',
              }}
              priority
            />,
            <Image
              alt    = "operator holding tablet"
              src    = "https://kroemtech-public.s3.eu-central-1.amazonaws.com/kroemtech_website/index/operator_holding_tablet.webp"
              width  = {screenDimensions.width}
              height = {screenDimensions.height}
              style  = {{
                boxShadow:      !mobileMode ? 'rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px' : undefined,
                filter:         `brightness(${brightness.toFixed(0)}%)`,
                objectFit:      'cover',
                objectPosition: `${viewPointW}% ${viewPointW}%`,
                transition:     'height 300ms',
              }}
              priority
            />,
            <Image
              alt    = "farming drone"
              src    = "https://kroemtech-public.s3.eu-central-1.amazonaws.com/kroemtech_website/index/farming_drone.webp"
              width  = {screenDimensions.width}
              height = {screenDimensions.height}
              style  = {{
                boxShadow:      !mobileMode ? 'rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px' : undefined,
                filter:         `brightness(${brightness.toFixed(0)}%)`,
                objectFit:      'cover',
                objectPosition: `${viewPointW}% ${viewPointW}%`,
                transition:     'height 300ms',
              }}
              priority
            />,
            <Image
              alt    = "managers looking at dashboard"
              src    = "https://kroemtech-public.s3.eu-central-1.amazonaws.com/kroemtech_website/index/managers_looking_at_dashboard.webp"
              width  = {screenDimensions.width}
              height = {screenDimensions.height}
              style  = {{
                boxShadow:      !mobileMode ? 'rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px' : undefined,
                filter:         `brightness(${brightness.toFixed(0)}%)`,
                objectFit:      'cover',
                objectPosition: `${viewPointW}% ${viewPointW}%`,
                transition:     'height 300ms',
              }}
              priority
            />,
          ]}
        />
      </Box>
      <Box
        style = {classes.subsectionDiv}
        sx = {{
          padding:        getPadding(),
          zIndex:         10,
          justifyContent: !mobileMode ? 'left' : 'center',
          marginTop:      screenDimensions.width <= breakpoints.desktopToMobile ? '10rem' : '12rem',
          width:          screenDimensions.width,
          maxWidth:       '1000px',
        }}
      >
        <FadeIn
          delay = {2000}
          transitionDuration = {300}
          visible
        >
          <Typography
            variant = "h1"
            fontSize = {getFontH1()}
            style = {{
              marginBottom: '100px',
              textAlign:    !mobileMode ? 'left' : 'center',
              lineHeight:   !mobileMode ? 1.3 : 1.75,
              color:        '#fff',
            }}
          >
            {t('hero.h1.1')}
            <br />
            {t('hero.h1.2')}
            <br />
          </Typography>
        </FadeIn>

        {getH2s()}

        <FadeIn
          delay = {4000}
          transitionDuration = {300}
          visible
        >
          <Typography
            variant = "body1"
            fontSize = {getFontBody()}
            style = {{
              marginBottom: '100px',
              textAlign:    !mobileMode ? 'left' : 'center',
              lineHeight:   !mobileMode ? 1.3 : 1.5,
              color:        '#fff',
            }}
          >
            {t('hero.body')}
          </Typography>
        </FadeIn>

        <Box
          style = {classes.linkDiv}
          sx = {{ justifyContent: mobileMode ? 'center' : 'left' }}
        >
          <FadeIn
            delay = {4000}
            transitionDuration = {300}
            visible
          >
            <Link href = {translateHref(currentLocale, 'services')}>
              <Button
                variant = "contained"
                style = {classes.linkButton}
              >
                {t('learnMore')}
              </Button>
            </Link>
          </FadeIn>
        </Box>

      </Box>

    </Box>
  )

  // *** MAIN RENDER ***
  return (

    <Box
      component = "section"
      style = {{
        height:       screenDimensions.height,
        marginBottom: '7rem',
      }}
    >
      {getSection()}
    </Box>

  )
}
