// React, Next
import { useTranslation } from 'next-i18next'
import {
  useContext, useState,
} from 'react'

// MUI
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import Zoom from '@mui/material/Zoom'
import { Button } from '@mui/material'

// Custom
import { GlobalContext } from 'context/GlobalContextProvider'
import FormDropdown from 'components/tools/dropdowns/FormDropdown'
import FormTextInput from 'components/tools/inputs/FormTextInput'
import {
  isAddress, isCompanyName, isEmail, isName, isPhoneNumber,
} from 'assets/functions/validation'
import PhoneInput from 'components/tools/inputs/PhoneInput'
import InfoMessageModal from 'components/modals/InfoMessageModal'

// Api
import { ContactFormInterface } from 'api/contact/interfaces'
import { SendContactForm } from 'api/contact/contact'

// Style
import breakpoints from 'assets/theme/breakpoints'
import themeColors from 'assets/theme/colors'
import classes from './ContactForm-CSS'

export default function ContactForm() {

  const { t } = useTranslation('contact')

  const { screenDimensions } = useContext(GlobalContext)

  const namePrefixOptions = [
    { value: 'male', label: t('form.mr') }, { value: 'female', label: t('form.mrs') },
  ]

  // error
  const [showError,            setShowError]            = useState(false)
  const [showInfoMessageModal, setShowInfoMessageModal] = useState(false)

  // mandatory
  const [namePrefix,        setNamePrefix]    = useState('')
  const [userFirstName,     setUserFirstName] = useState('')
  const [userLastName,      setUserLastName]  = useState('')
  const [userEmail,         setUserEmail]     = useState('')

  // optional
  const [userPhone,         setUserPhone]        = useState<string>('')
  const [phoneCountryCode,  setPhoneCountryCode] = useState<string>('')
  const [company,           setCompany]          = useState<null|string>('')
  const [message,           setMessage]          = useState<string>('')

  const onGenderChange     = (val:string) => {
    setNamePrefix(val)
    setShowError(false)
  }
  const onFirstNameChange  = (val:string) => {
    setUserFirstName(val)
    setShowError(false)
  }
  const onLastNameChange   = (val:string) => {
    setUserLastName(val)
    setShowError(false)
  }
  const onEmailChange      = (val:string) => {
    setUserEmail(val)
    setShowError(false)
  }
  const onCompanyChange    = (val:string) => {
    setCompany(val)
    setShowError(false)
  }

  const onMessageChange    = (val:string) => {
    setMessage(val)
    setShowError(false)
  }
  const onPhoneChange = (code:string, phone:string) => {
    setPhoneCountryCode(code)
    setUserPhone(`${code}:${phone}`)
    setShowError(false)
  }

  const validateInput = (type:string, val:string|null) => {
    let input = val
    if (input === null) input = ''
    let len = 0
    if (input !== undefined && input !== null) len = input.length
    switch (type) {
      case 'namePrefix':
        if (len === 0) return 0
        return 1
      case 'firstName':
        if (input.length === 0) return 0
        if (!isName(input) || len < 2 || len > 30) return -1
        return 1
      case 'lastName':
        if (input.length === 0) return 0
        if (!isName(input) || len < 2 || len > 50) return -1
        return 1
      case 'email':
        if (input.length === 0) return 0
        if (isEmail(input)) return 1
        return -1
      case 'country':
        if (len === 0) return 0
        return 1
      case 'phone':
        if (len === 0) return 0
        if (input !== null && isPhoneNumber(phoneCountryCode.split('|')[0], input)) return 1
        return -1
      case 'address':
        if (len === 0) return 0
        if (!isAddress(input, 2) || len < 5 || len > 700) return -1
        return 1
      case 'city':
        if (len === 0) return 0
        if (!isName(input) || len > 100) return -1
        return 1
      case 'company':
        if (len === 0) return 0
        if (!isCompanyName(input) || len > 100) return -1
        return 1
      case 'job':
        if (len === 0) return 0
        if (!isName(input) || len > 100) return -1
        return 1
      case 'message':
        if (len === 0) return 0
        if (len > 8000) return -1
        return 1
    }
  }

  const getSubmitEnabled = () => (
    validateInput('namePrefix', namePrefix)
      && validateInput('firstName', userFirstName)
      && validateInput('lastName', userLastName)
      && validateInput('email', userEmail)
      && validateInput('message', message)
  )

  const onSubmit = async () => {
    if (getSubmitEnabled()) {

      const formContent:ContactFormInterface = {
        name_prefix: namePrefix,
        first_name:  userFirstName,
        last_name:   userLastName,
        company,
        email:       userEmail,
        phone:       userPhone,
        message,
      }

      const res = await SendContactForm(formContent)

      if (res.ok) {                                                             // ON SUCCESS
      // clear form
        setNamePrefix('')
        setUserFirstName('')
        setUserLastName('')
        setUserEmail('')
        setCompany('')
        setMessage('')
        setUserPhone('')

        setShowInfoMessageModal(true)
      }
      else setShowError(true)                                                   // ON ERROR

    }
  }

  const getMandatoryLabel = (label:string) => (
    <>
      {label}
      <span style = {{ color: themeColors.error.main }}> *</span>
    </>
  )

  return (

    <>
      <InfoMessageModal
        show    = {showInfoMessageModal}
        close   = {() => setShowInfoMessageModal(false)}
        content = {
          {
            mode:    'success',
            title:   t('confirmation.title'),
            message: t('confirmation.message'),
          }
        }
        width = {400}
        height = {50}
      />
      <div style = {{ display: screenDimensions.width < breakpoints.mobileMid ? 'block' : 'flex' }}>
        <FormDropdown
          title          = {getMandatoryLabel(t('form.prefix'))}
          options        = {namePrefixOptions}
          onChange       = {onGenderChange}
          selectedOption = {namePrefix}
          validation     = {validateInput('namePrefix', namePrefix)}
          marginBottom    = "1rem"
        />
        <FormTextInput
          title           = {getMandatoryLabel(t('form.fName'))}
          placeholder     = {t('form.fName')}
          value           = {userFirstName === undefined || userFirstName === null ? '' : userFirstName}
          onChange        = {onFirstNameChange}
          onClick         = {() => {}}
          onBlur          = {() => {}}
          validation      = {validateInput('firstName', userFirstName)}
          marginBottom    = "1rem"
          marginLeft      = {screenDimensions.width < breakpoints.mobileMid ? 0 : '0.625rem'}
          marginRight     = {screenDimensions.width < breakpoints.mobileMid ? 0 : '0.625rem'}
        />
        <FormTextInput
          title           = {getMandatoryLabel(t('form.lName'))}
          placeholder     = {t('form.lName')}
          value           = {userLastName === undefined || userLastName === null ? '' : userLastName}
          onChange        = {onLastNameChange}
          onClick         = {() => {}}
          onBlur          = {() => {}}
          validation      = {validateInput('lastName', userLastName)}
          marginBottom    = "1rem"
        />
      </div>
      <div style = {{ display: screenDimensions.width < breakpoints.desktopToMobile ? 'block' : 'flex' }}>
        <FormTextInput
          title           = {t('form.company')}
          placeholder     = {t('form.company')}
          value           = {company === undefined || company === null ? '' : company}
          onChange        = {onCompanyChange}
          onClick         = {() => {}}
          onBlur          = {() => {}}
          validation      = {validateInput('company', company)}
          marginBottom    = "1rem"
        />
      </div>
      <div style = {{ display: 'flex' }}>
        <FormTextInput
          title           = {getMandatoryLabel(t('form.email'))}
          placeholder     = "your@email.com"
          value           = {userEmail === undefined || userEmail === null ? '' : userEmail}
          onChange        = {onEmailChange}
          onClick         = {() => {}}
          onBlur          = {() => {}}
          validation      = {validateInput('email', userEmail)}
          marginBottom    = "1rem"
        />
      </div>
      <div style = {{ display: 'flex' }}>
        <PhoneInput
          title           = {t('form.phone')}
          placeholder     = "+99 99 999..."
          value           = {userPhone === undefined || userPhone === null ? '' : userPhone}
          onChange        = {onPhoneChange}
          onClick         = {() => {}}
          onBlur          = {() => {}}
          validation      = {validateInput('phone', userPhone.split(':')[1] !== undefined ? userPhone.split(':')[1] : '')}
          marginBottom    = "1rem"
          prefixWidth     = {screenDimensions.width < breakpoints.mobileSmall ? '100%' : '40%'}
          display         = {screenDimensions.width < breakpoints.mobileSmall ? 'vertical' : 'horizontal'}
        />
      </div>

      <div style = {{ display: 'flex' }}>
        <FormTextInput
          title        = {getMandatoryLabel(t('form.message'))}
          placeholder  = {`${t('form.message')}...`}
          value        = {message === undefined || message === null ? '' : message}
          onChange     = {onMessageChange}
          onClick      = {() => {}}
          onBlur       = {() => {}}
          validation   = {validateInput('message', message)}
          marginBottom = "1rem"
          multiline
          rows           = {6}
          maxRows        = {12}
          height         = {250}
          inputMarginTop = "5px"
        />
      </div>
      <Typography
        fontSize  = {11}
        marginTop = "-10px"
        textAlign = "right"
      >
        {`${message.length} / 8000 ${t('form.chars')}`}

      </Typography>
      <div style = {{ display: 'flex', justifyContent: 'center' }}>
        <Tooltip
          arrow
          TransitionComponent = {Zoom}
          title      = {getSubmitEnabled() ? '' : <div style = {{ fontSize: '0.875rem' }}>Please fill out all mandatory fields (*)</div>}
          placement  = "top"
          enterDelay = {300}
        >
          <Button
            sx = {getSubmitEnabled() ? classes.submitButton : classes.submitButtonDisabled}
            style     = {{ outline: 'none' }}
            onClick   = {onSubmit}
          >
            {t('form.send')}
          </Button>
        </Tooltip>
      </div>
      {
        !showError ? null
          : (
            <Typography
              fontSize  = {13}
              marginTop = "10px"
              textAlign = "center"
              style     = {{
                color: themeColors.error.main,
              }}
            >
              {t('error')}
            </Typography>
          )
      }
    </>

  )
}
