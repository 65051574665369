import themeColors from '@/assets/theme/colors'

const classes = {
  embla: {
    position:        'relative' as 'relative',
    backgroundColor: 'transparent',
    padding:         '0px',
    marginLeft:      '0px',
    marginRight:     'auto',
    marginBottom:    '0px',
  },
  emblaViewport: {
    overflow: 'hidden',
  },
  emblaContainer: {
    display:    'flex',
    marginLeft: '0px',
  },
  emblaSlide: {
    position:    'relative' as 'relative',
    paddingLeft: '0px', // space between slides
  },
  emblaSlideInner: {
    position:     'relative' as 'relative',
    overflow:     'hidden',
    boxShadow:    'none',
    marginBottom: '0px',
  },
  emblaButton: {
    outline:         0,
    cursor:          'pointer',
    backgroundColor: 'rgba(255,255,255,0.3)',
    borderRadius:    '50%',
    display:         'flex',
    alignItems:      'center',
    justifyContent:  'center',
    touchAction:     'manipulation',
    marginTop:       30,
    position:        'absolute' as 'absolute',
    zIndex:          20,
    transform:       'translateY(-50%)',
    border:          0,
    width:           '30px',
    height:          '30px',
    fill:            '#1bcacd',
    padding:         0,
  },
  arrowButtonColor: {
    color:     themeColors.gray.dark,
    '&:hover': {
      color: themeColors.info.main,
    },
  },
}

export default classes
