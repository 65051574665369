// React functions
import React, { useEffect, useState, ReactElement } from 'react'

// React UI
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import InputBase from '@mui/material/InputBase'
import CheckIcon from '@mui/icons-material/Check'
import Zoom from '@mui/material/Zoom'
import Typography from '@mui/material/Typography'

// Icons
import PersonIcon from '@mui/icons-material/Person'
import EmailIcon from '@mui/icons-material/Email'
import LockIcon from '@mui/icons-material/Lock'

// import './PhoneInput.css'
import CountryListAllIsoData from 'assets/lists/country_list'
import { TooltipPlacement } from '@/types/global_types'
import PhonePrefixDropdown from '../dropdowns/PhonePrefixDropdown'

type IconList = 'person'|'email'|'check'|'lock'

type Props = {
  preventCopy?:       boolean,
  preventPaste?:      boolean,
  icon?:              IconList,
  validation?:        null|number,
  title?:             string,
  tooltip?:           string|ReactElement,
  tooltipPlacement?:  TooltipPlacement,
  width?:             number,
  prefixWidth?:       number|string,
  height?:            number,
  marginBottom?:      number|string,
  marginTop?:         number|string,
  iconColor?:         string,
  color?:             string,
  placeholder:        string,
  disabled?:          boolean,
  inputMarginLeft?:   number|string,
  type?:              'text'|'number'|'password',
  display?:           'horizontal'|'vertical',
  value:              string,
  onEnterKeyDown?:    () => void,
  onClick:            () => void,
  onBlur:             () => void,
  onChange:           (code:string, prefix: string) => void
}

export default function PhoneInput(props:Props) {

  const [inputFocused, setInputFocused] = useState(false)
  const [prefix, setPrefix] = useState('')

  useEffect(() => {
    if (props.value.split(':').length > 1) {
      getPrefixValue(props.value.split(':')[1])
    }
    else setPrefix('')
  }, [props.value])

  const onChange  = (e:any) => {                                                                                        // country code: CH|+41, value: +41...
    const val = e.target.value
    if (prefix.length === 0 && val.length > 0) props.onChange(getPrefixValue(val), val)
    else props.onChange(prefix, val)
  }
  const onClick   = () => { props.onClick() }
  const onBlur    = () => {
    setInputFocused(false)
    props.onBlur()
  }
  const onCopy    = (e:any) => { if (props.preventCopy) e.preventDefault() }
  const onPaste   = (e:any) => { if (props.preventPaste) e.preventDefault() }

  const onEnterKeyDown = (e:any) => {
    if (e.keyCode === 13 && props.onEnterKeyDown !== undefined) props.onEnterKeyDown()
  }

  const onPrefixChange = (val:string) => {
    setPrefix(val)
    if (val === '') props.onChange('', '')
    else {
      props.onChange(val, val.split('|')[1])                                                                            // country code: CH|+41, prefix: +41
    }
  }

  const getIcon = () => {
    switch (props.icon) {
      case 'person':    return <PersonIcon />
      case 'email':     return <EmailIcon />
      case 'check':     return <CheckIcon />
      case 'lock':      return <LockIcon />
    }
  }

  const getBorder = () => {
    if (props.validation === 1)   return '1px solid rgb(0,200,0)'                                                       // -1 = input rejected, 1 = validated, 0 = default(no input)
    if (props.validation === -1)  return '1px solid rgb(200,0,0)'
    if (inputFocused)             return '1px solid rgb(90,110,205)'
    return '1px solid rgb(255,255,255)'
  }

  const getPrefixValue = (nr:string) => {

    let pref = ''

    // +1-264
    if (nr.includes('-')) pref = nr.substring(0, 6)

    // +220
    let res = CountryListAllIsoData.filter((f) => f.phone === nr.substring(0, 4))
    if (res.length > 0 && pref === '') pref = `${res[0].code}|${nr.substring(0, 4)}`

    // +41
    res = CountryListAllIsoData.filter((f) => f.phone === nr.substring(0, 3))
    if (res.length > 0 && pref === '') pref = `${res[0].code}|${nr.substring(0, 3)}`

    // +1
    res = CountryListAllIsoData.filter((f) => f.phone === nr.substring(0, 2))
    if (res.length > 0 && pref === '') pref = `${res[0].code}|${nr.substring(0, 2)}`

    setPrefix(pref)
    return pref
  }

  return (
    <div
      style = {{
        width: '100%',
      }}
    >
      {
        props.title === undefined ? null
          : (
            <Box
              style = {{
                display:        'flex',
                justifyContent: 'left',
                alignItems:     'center',
                background:     'none',
                marginBottom:   -5,
                marginLeft:     2,
              }}
            >
              <Typography
                style = {{
                  fontWeight: 600,
                  fontSize:   '0.875rem',
                }}
              >
                {props.title}
              </Typography>
            </Box>
          )
      }
      <Box
        style = {{
          display:      props.display === 'vertical' ? 'block' : 'flex',
          alignItems:   'center',
          marginTop:    props.marginTop === undefined ? '10px' : props.marginTop,
          marginBottom: props.marginBottom === undefined ? '10px' : props.marginBottom,
        }}
      >
        <Box
          style = {{
            display:        'flex',
            justifyContent: 'left',
            alignItems:     'center',
            width:          props.prefixWidth === undefined ? '60%' : props.prefixWidth,
            height:         props.height === undefined ? 40 : props.height,
            background:     '#fff',
            borderRadius:   3,
            transition:     'border ease-in 0.2s',
            marginBottom:   props.display === 'vertical' ? 5 : 0,
          }}
        >
          <PhonePrefixDropdown
            selectedOption = {prefix}
            disabled       = {props.disabled === undefined ? false : props.disabled}
            onChange       = {onPrefixChange}
          />
        </Box>
        <Tooltip
          TransitionComponent = {Zoom}
          arrow
          title      = {props.tooltip === undefined ? '' : <div style = {{ fontSize: '0.875rem' }}>{props.tooltip}</div>}
          placement  = {props.tooltipPlacement === undefined ? 'right' : props.tooltipPlacement}
          enterDelay = {300}
        >
          <Box
            style = {{
              display:        'flex',
              justifyContent: 'left',
              alignItems:     'center',
              width:          props.width === undefined ? '100%' : props.width,
              height:         props.height === undefined ? 40 : props.height,
              background:     '#fff',
              borderRadius:   3,
              border:         getBorder(),
              transition:     'border ease-in 0.2s',
            }}
          >
            <Box
              style = {{
                display:        'flex',
                justifyContent: 'left',
                alignItems:     'center',
                width:          props.width === undefined ? '100%' : props.width,
                height:         props.height === undefined ? 40 : props.height,
                boxShadow:      '0px 1px 2px 0px #00000022',
              }}
            >
              <Box
                style = {{
                  marginLeft: 10,
                  color:      props.iconColor === undefined ? '#666' : props.iconColor,
                }}
              >
                {getIcon()}
              </Box>

              <InputBase
                placeholder = {props.placeholder}
                disabled    = {props.disabled === undefined ? false : props.disabled}
                style       = {{
                  marginLeft: (props.inputMarginLeft === undefined ? 0 : props.inputMarginLeft),
                  width:      '100%',
                }}
                type       = {props.type === undefined ? 'text' : props.type}
                inputProps = {{
                  style: {
                    color:      props.color === undefined ? '#666' : props.color,
                    fontSize:   '0.875rem',
                    height:     props.height === undefined ? 28 : (props.height - 10),
                    textAlign:  'left',
                    width:      '98%',
                    border:     '0px solid #fff',
                    background: 'RBGA(0,0,0,0)',
                  },
                }}
                value     = {props.value.split(':')[1] !== undefined ? props.value.split(':')[1] : ''}
                onFocus   = {() => setInputFocused(true)}
                onChange  = {onChange}
                onBlur    = {onBlur}
                onClick   = {onClick}
                onCopy    = {onCopy}
                onPaste   = {onPaste}
                onKeyDown = {onEnterKeyDown}
              />

            </Box>
          </Box>
        </Tooltip>
      </Box>
    </div>
  )
}
