const classes = {
  section: {
    paddingBottom: '4rem',
    paddingLeft:   '1.25rem',
    paddingRight:  '1.25rem',
  },
  gridBox: {
    display: 'grid',
    rowGap:  '2rem',
  },
  cardBox: {
    display:       'flex',
    margin:        '10px',
    background:    '#fff',
    flexDirection: 'column' as const,
    '&:hover':     {
      cursor:     'pointer',
      transform:  'scale(1.03)',
      transition: 'transform 200ms',
    },
  },
  typeH4: {
    fontWeight: 400,
    margin:     '0 15px 0 15px',
    height:     '50px',
  },
}

export default classes
