import themeColors from '@/assets/theme/colors'

const classes = {
  section: {
    display:        'flex',
    justifyContent: 'center',
    paddingTop:     '4.5rem',
    paddingBottom:  '4.5rem',
    background:     themeColors.gray.lightest,
  },
  sectionMobile: {
    paddingTop:    '4.5rem',
    paddingBottom: '4.5rem',
    background:    themeColors.gray.lightest,
  },
  greenBox: {
    width:        '40vw',
    background:   '#D9F2D0',
    marginTop:    '2rem',
    marginBottom: '2rem',
  },
  greenBoxMobile: {
    width:        '100%',
    background:   '#D9F2D0',
    marginTop:    '2rem',
    marginBottom: '2rem',
  },
  imgBox: {
    width:  '75vw',
    height: '100%',
  },
  imgBoxMobile: {
    width:  '100%',
    height: 'auto',
  },
  subsectionDiv: {
    zIndex:       2,
    paddingLeft:  '1.875rem',
    paddingRight: '1.875rem',
  },
  icon: {
    width:        20,
    height:       20,
    marginRight:  '0.5rem',
    marginBottom: '-0.2rem',
  },
  card: {
    boxShadow:    'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
    borderRadius: '10px',
    background:   '#fff',
    padding:      '1.875rem',
    zIndex:       1,
    position:     'absolute',
  },
  cardMobile: {
    boxShadow:    'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
    borderRadius: '10px',
    background:   'rgba(255, 255, 255, 0.8)',
    padding:      '1.875rem',
    zIndex:       1,
    margin:       '-50px 30px 0 30px',
    position:     'absolute',
  },
  learnMoreBox: {
    display:        'flex',
    justifyContent: 'right',
    marginTop:      '1rem',
  },
  learnMoreTypo: {
    color:      themeColors.kroemtech.sensBlue,
    fontWeight: 600,
    fontSize:   '1rem',
    '&:hover':  {
      color: `${themeColors.kroemtech.sensBlue}cc`,
    },
  },
}

export default classes
