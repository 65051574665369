const countryListAllIsoData = [{
  code:   'AF',
  code3:  'AFG',
  name:   'Afghanistan',
  number: '004',
  phone:  '+93',
}, {
  code:   'AL',
  code3:  'ALB',
  name:   'Albania',
  number: '008',
  phone:  '+355',
}, {
  code:   'DZ',
  code3:  'DZA',
  name:   'Algeria',
  number: '012',
  phone:  '+213',
}, {
  code:   'AS',
  code3:  'ASM',
  name:   'American Samoa',
  number: '016',
  phone:  '+1-684',
}, {
  code:   'AD',
  code3:  'AND',
  name:   'Andorra',
  number: '020',
  phone:  '+376',
}, {
  code:   'AO',
  code3:  'AGO',
  name:   'Angola',
  number: '024',
  phone:  '+244',
}, {
  code:   'AI',
  code3:  'AIA',
  name:   'Anguilla',
  number: '660',
  phone:  '+1-264',
}, {
  code:   'AQ',
  code3:  'ATA',
  name:   'Antarctica',
  number: '010',
  phone:  '+672',
}, {
  code:   'AG',
  code3:  'ATG',
  name:   'Antigua and Barbuda',
  number: '028',
  phone:  '+1-268',
}, {
  code:   'AR',
  code3:  'ARG',
  name:   'Argentina',
  number: '032',
  phone:  '+54',
}, {
  code:   'AM',
  code3:  'ARM',
  name:   'Armenia',
  number: '051',
  phone:  '+374',
}, {
  code:   'AW',
  code3:  'ABW',
  name:   'Aruba',
  number: '533',
  phone:  '+297',
}, {
  code:   'AU',
  code3:  'AUS',
  name:   'Australia',
  number: '036',
  phone:  '+61',
}, {
  code:   'AT',
  code3:  'AUT',
  name:   'Austria',
  number: '040',
  phone:  '+43',
}, {
  code:   'AZ',
  code3:  'AZE',
  name:   'Azerbaijan',
  number: '031',
  phone:  '+994',
}, {
  code:   'BS',
  code3:  'BHS',
  name:   'Bahamas (the)',
  number: '044',
  phone:  '+1-242',
}, {
  code:   'BH',
  code3:  'BHR',
  name:   'Bahrain',
  number: '048',
  phone:  '+973',
}, {
  code:   'BD',
  code3:  'BGD',
  name:   'Bangladesh',
  number: '050',
  phone:  '+880',
}, {
  code:   'BB',
  code3:  'BRB',
  name:   'Barbados',
  number: '052',
  phone:  '+1-246',
}, {
  code:   'BY',
  code3:  'BLR',
  name:   'Belarus',
  number: '112',
  phone:  '+375',
}, {
  code:   'BE',
  code3:  'BEL',
  name:   'Belgium',
  number: '056',
  phone:  '+32',
}, {
  code:   'BZ',
  code3:  'BLZ',
  name:   'Belize',
  number: '084',
  phone:  '+501',
}, {
  code:   'BJ',
  code3:  'BEN',
  name:   'Benin',
  number: '204',
  phone:  '+229',
}, {
  code:   'BM',
  code3:  'BMU',
  name:   'Bermuda',
  number: '060',
  phone:  '+1-441',
}, {
  code:   'BT',
  code3:  'BTN',
  name:   'Bhutan',
  number: '064',
  phone:  '+975',
}, {
  code:   'BO',
  code3:  'BOL',
  name:   'Bolivia (Plurinational State of)',
  number: '068',
  phone:  '+591',
}, {
  code:   'BQ',
  code3:  'BES',
  name:   'Bonaire, Sint Eustatius and Saba',
  number: '535',
  phone:  undefined,
}, {
  code:   'BA',
  code3:  'BIH',
  name:   'Bosnia and Herzegovina',
  number: '070',
  phone:  '+387',
}, {
  code:   'BW',
  code3:  'BWA',
  name:   'Botswana',
  number: '072',
  phone:  '+267',
}, {
  code:   'BV',
  code3:  'BVT',
  name:   'Bouvet Island',
  number: '074',
  phone:  '+47',
}, {
  code:   'BR',
  code3:  'BRA',
  name:   'Brazil',
  number: '076',
  phone:  '+55',
}, {
  code:   'IO',
  code3:  'IOT',
  name:   'British Indian Ocean Territory (the)',
  number: '086',
  phone:  '+246',
}, {
  code:   'BN',
  code3:  'BRN',
  name:   'Brunei Darussalam',
  number: '096',
  phone:  '+673',
}, {
  code:   'BG',
  code3:  'BGR',
  name:   'Bulgaria',
  number: '100',
  phone:  '+359',
}, {
  code:   'BF',
  code3:  'BFA',
  name:   'Burkina Faso',
  number: '854',
  phone:  '+226',
}, {
  code:   'BI',
  code3:  'BDI',
  name:   'Burundi',
  number: '108',
  phone:  '+257',
}, {
  code:   'CV',
  code3:  'CPV',
  name:   'Cabo Verde',
  number: '132',
  phone:  '+238',
}, {
  code:   'KH',
  code3:  'KHM',
  name:   'Cambodia',
  number: '116',
  phone:  '+855',
}, {
  code:   'CM',
  code3:  'CMR',
  name:   'Cameroon',
  number: '120',
  phone:  '+237',
}, {
  code:   'CA',
  code3:  'CAN',
  name:   'Canada',
  number: '124',
  phone:  '+1',
}, {
  code:   'KY',
  code3:  'CYM',
  name:   'Cayman Islands (the)',
  number: '136',
  phone:  '+1-345',
}, {
  code:   'CF',
  code3:  'CAF',
  name:   'Central African Republic (the)',
  number: '140',
  phone:  '+236',
}, {
  code:   'TD',
  code3:  'TCD',
  name:   'Chad',
  number: '148',
  phone:  '+235',
}, {
  code:   'CL',
  code3:  'CHL',
  name:   'Chile',
  number: '152',
  phone:  '+56',
}, {
  code:   'CN',
  code3:  'CHN',
  name:   'China',
  number: '156',
  phone:  '+86',
}, {
  code:   'CX',
  code3:  'CXR',
  name:   'Christmas Island',
  number: '162',
  phone:  '+61',
}, {
  code:   'CC',
  code3:  'CCK',
  name:   'Cocos (Keeling) Islands (the)',
  number: '166',
  phone:  '+61',
}, {
  code:   'CO',
  code3:  'COL',
  name:   'Colombia',
  number: '170',
  phone:  '+57',
}, {
  code:   'KM',
  code3:  'COM',
  name:   'Comoros (the)',
  number: '174',
  phone:  '+269',
}, {
  code:   'CD',
  code3:  'COD',
  name:   'Congo (the Democratic Republic of the)',
  number: '180',
  phone:  '+243',
}, {
  code:   'CG',
  code3:  'COG',
  name:   'Congo (the)',
  number: '178',
  phone:  '+242',
}, {
  code:   'CK',
  code3:  'COK',
  name:   'Cook Islands (the)',
  number: '184',
  phone:  '+682',
}, {
  code:   'CR',
  code3:  'CRI',
  name:   'Costa Rica',
  number: '188',
  phone:  '+506',
}, {
  code:   'HR',
  code3:  'HRV',
  name:   'Croatia',
  number: '191',
  phone:  '+385',
}, {
  code:   'CU',
  code3:  'CUB',
  name:   'Cuba',
  number: '192',
  phone:  '+53',
}, {
  code:   'CW',
  code3:  'CUW',
  name:   'Curaçao',
  number: '531',
  phone:  '+599',
}, {
  code:   'CY',
  code3:  'CYP',
  name:   'Cyprus',
  number: '196',
  phone:  '+357',
}, {
  code:   'CZ',
  code3:  'CZE',
  name:   'Czechia',
  number: '203',
  phone:  '+420',
}, {
  code:   'CI',
  code3:  'CIV',
  name:   "Côte d'Ivoire",
  number: '384',
  phone:  '+225',
}, {
  code:   'DK',
  code3:  'DNK',
  name:   'Denmark',
  number: '208',
  phone:  '+45',
}, {
  code:   'DJ',
  code3:  'DJI',
  name:   'Djibouti',
  number: '262',
  phone:  '+253',
}, {
  code:   'DM',
  code3:  'DMA',
  name:   'Dominica',
  number: '212',
  phone:  '+1-767',
}, {
  code:   'DO',
  code3:  'DOM',
  name:   'Dominican Republic (the)',
  number: '214',
  phone:  '+1-809',
}, {
  code:   'EC',
  code3:  'ECU',
  name:   'Ecuador',
  number: '218',
  phone:  '+593',
}, {
  code:   'EG',
  code3:  'EGY',
  name:   'Egypt',
  number: '818',
  phone:  '+20',
}, {
  code:   'SV',
  code3:  'SLV',
  name:   'El Salvador',
  number: '222',
  phone:  '+503',
}, {
  code:   'GQ',
  code3:  'GNQ',
  name:   'Equatorial Guinea',
  number: '226',
  phone:  '+240',
}, {
  code:   'ER',
  code3:  'ERI',
  name:   'Eritrea',
  number: '232',
  phone:  '+291',
}, {
  code:   'EE',
  code3:  'EST',
  name:   'Estonia',
  number: '233',
  phone:  '+372',
}, {
  code:   'SZ',
  code3:  'SWZ',
  name:   'Eswatini',
  number: '748',
  phone:  '+268',
}, {
  code:   'ET',
  code3:  'ETH',
  name:   'Ethiopia',
  number: '231',
  phone:  '+251',
}, {
  code:   'FK',
  code3:  'FLK',
  name:   'Falkland Islands (the) [Malvinas]',
  number: '238',
  phone:  '+500',
}, {
  code:   'FO',
  code3:  'FRO',
  name:   'Faroe Islands (the)',
  number: '234',
  phone:  '+298',
}, {
  code:   'FJ',
  code3:  'FJI',
  name:   'Fiji',
  number: '242',
  phone:  '+679',
}, {
  code:   'FI',
  code3:  'FIN',
  name:   'Finland',
  number: '246',
  phone:  '+358',
}, {
  code:   'FR',
  code3:  'FRA',
  name:   'France',
  number: '250',
  phone:  '+33',
}, {
  code:   'GF',
  code3:  'GUF',
  name:   'French Guiana',
  number: '254',
  phone:  '+594',
}, {
  code:   'PF',
  code3:  'PYF',
  name:   'French Polynesia',
  number: '258',
  phone:  '+689',
}, {
  code:   'TF',
  code3:  'ATF',
  name:   'French Southern Territories (the)',
  number: '260',
  phone:  '+262',
}, {
  code:   'GA',
  code3:  'GAB',
  name:   'Gabon',
  number: '266',
  phone:  '+241',
}, {
  code:   'GM',
  code3:  'GMB',
  name:   'Gambia (the)',
  number: '270',
  phone:  '+220',
}, {
  code:   'GE',
  code3:  'GEO',
  name:   'Georgia',
  number: '268',
  phone:  '+995',
}, {
  code:   'DE',
  code3:  'DEU',
  name:   'Germany',
  number: '276',
  phone:  '+49',
}, {
  code:   'GH',
  code3:  'GHA',
  name:   'Ghana',
  number: '288',
  phone:  '+233',
}, {
  code:   'GI',
  code3:  'GIB',
  name:   'Gibraltar',
  number: '292',
  phone:  '+350',
}, {
  code:   'GR',
  code3:  'GRC',
  name:   'Greece',
  number: '300',
  phone:  '+30',
}, {
  code:   'GL',
  code3:  'GRL',
  name:   'Greenland',
  number: '304',
  phone:  '+299',
}, {
  code:   'GD',
  code3:  'GRD',
  name:   'Grenada',
  number: '308',
  phone:  '+1-473',
}, {
  code:   'GP',
  code3:  'GLP',
  name:   'Guadeloupe',
  number: '312',
  phone:  '+590',
}, {
  code:   'GU',
  code3:  'GUM',
  name:   'Guam',
  number: '316',
  phone:  '+1-671',
}, {
  code:   'GT',
  code3:  'GTM',
  name:   'Guatemala',
  number: '320',
  phone:  '+502',
}, {
  code:   'GG',
  code3:  'GGY',
  name:   'Guernsey',
  number: '831',
  phone:  '+44',
}, {
  code:   'GN',
  code3:  'GIN',
  name:   'Guinea',
  number: '324',
  phone:  '+224',
}, {
  code:   'GW',
  code3:  'GNB',
  name:   'Guinea-Bissau',
  number: '624',
  phone:  '+245',
}, {
  code:   'GY',
  code3:  'GUY',
  name:   'Guyana',
  number: '328',
  phone:  '+592',
}, {
  code:   'HT',
  code3:  'HTI',
  name:   'Haiti',
  number: '332',
  phone:  '+509',
}, {
  code:   'HM',
  code3:  'HMD',
  name:   'Heard Island and McDonald Islands',
  number: '334',
  phone:  '+672',
}, {
  code:   'VA',
  code3:  'VAT',
  name:   'Holy See (the)',
  number: '336',
  phone:  '+379',
}, {
  code:   'HN',
  code3:  'HND',
  name:   'Honduras',
  number: '340',
  phone:  '+504',
}, {
  code:   'HK',
  code3:  'HKG',
  name:   'Hong Kong',
  number: '344',
  phone:  '+852',
}, {
  code:   'HU',
  code3:  'HUN',
  name:   'Hungary',
  number: '348',
  phone:  '+36',
}, {
  code:   'IS',
  code3:  'ISL',
  name:   'Iceland',
  number: '352',
  phone:  '+354',
}, {
  code:   'IN',
  code3:  'IND',
  name:   'India',
  number: '356',
  phone:  '+91',
}, {
  code:   'ID',
  code3:  'IDN',
  name:   'Indonesia',
  number: '360',
  phone:  '+62',
}, {
  code:   'IR',
  code3:  'IRN',
  name:   'Iran (Islamic Republic of)',
  number: '364',
  phone:  '+98',
}, {
  code:   'IQ',
  code3:  'IRQ',
  name:   'Iraq',
  number: '368',
  phone:  '+964',
}, {
  code:   'IE',
  code3:  'IRL',
  name:   'Ireland',
  number: '372',
  phone:  '+353',
}, {
  code:   'IM',
  code3:  'IMN',
  name:   'Isle of Man',
  number: '833',
  phone:  '+44',
}, {
  code:   'IL',
  code3:  'ISR',
  name:   'Israel',
  number: '376',
  phone:  '+972',
}, {
  code:   'IT',
  code3:  'ITA',
  name:   'Italy',
  number: '380',
  phone:  '+39',
}, {
  code:   'JM',
  code3:  'JAM',
  name:   'Jamaica',
  number: '388',
  phone:  '+1-876',
}, {
  code:   'JP',
  code3:  'JPN',
  name:   'Japan',
  number: '392',
  phone:  '+81',
}, {
  code:   'JE',
  code3:  'JEY',
  name:   'Jersey',
  number: '832',
  phone:  '+44',
}, {
  code:   'JO',
  code3:  'JOR',
  name:   'Jordan',
  number: '400',
  phone:  '+962',
}, {
  code:   'KZ',
  code3:  'KAZ',
  name:   'Kazakhstan',
  number: '398',
  phone:  '+7',
}, {
  code:   'KE',
  code3:  'KEN',
  name:   'Kenya',
  number: '404',
  phone:  '+254',
}, {
  code:   'KI',
  code3:  'KIR',
  name:   'Kiribati',
  number: '296',
  phone:  '+686',
}, {
  code:   'KP',
  code3:  'PRK',
  name:   "Korea (the Democratic People's Republic of)",
  number: '408',
  phone:  '+850',
}, {
  code:   'KR',
  code3:  'KOR',
  name:   'Korea (the Republic of)',
  number: '410',
  phone:  '+82',
}, {
  code:   'KW',
  code3:  'KWT',
  name:   'Kuwait',
  number: '414',
  phone:  '+965',
}, {
  code:   'KG',
  code3:  'KGZ',
  name:   'Kyrgyzstan',
  number: '417',
  phone:  '+996',
}, {
  code:   'LA',
  code3:  'LAO',
  name:   "Lao People's Democratic Republic (the)",
  number: '418',
  phone:  '+856',
}, {
  code:   'LV',
  code3:  'LVA',
  name:   'Latvia',
  number: '428',
  phone:  '+371',
}, {
  code:   'LB',
  code3:  'LBN',
  name:   'Lebanon',
  number: '422',
  phone:  '+961',
}, {
  code:   'LS',
  code3:  'LSO',
  name:   'Lesotho',
  number: '426',
  phone:  '+266',
}, {
  code:   'LR',
  code3:  'LBR',
  name:   'Liberia',
  number: '430',
  phone:  '+231',
}, {
  code:   'LY',
  code3:  'LBY',
  name:   'Libya',
  number: '434',
  phone:  '+218',
}, {
  code:   'LI',
  code3:  'LIE',
  name:   'Liechtenstein',
  number: '438',
  phone:  '+423',
}, {
  code:   'LT',
  code3:  'LTU',
  name:   'Lithuania',
  number: '440',
  phone:  '+370',
}, {
  code:   'LU',
  code3:  'LUX',
  name:   'Luxembourg',
  number: '442',
  phone:  '+352',
}, {
  code:   'MO',
  code3:  'MAC',
  name:   'Macao',
  number: '446',
  phone:  '+853',
}, {
  code:   'MG',
  code3:  'MDG',
  name:   'Madagascar',
  number: '450',
  phone:  '+261',
}, {
  code:   'MW',
  code3:  'MWI',
  name:   'Malawi',
  number: '454',
  phone:  '+265',
}, {
  code:   'MY',
  code3:  'MYS',
  name:   'Malaysia',
  number: '458',
  phone:  '+60',
}, {
  code:   'MV',
  code3:  'MDV',
  name:   'Maldives',
  number: '462',
  phone:  '+960',
}, {
  code:   'ML',
  code3:  'MLI',
  name:   'Mali',
  number: '466',
  phone:  '+223',
}, {
  code:   'MT',
  code3:  'MLT',
  name:   'Malta',
  number: '470',
  phone:  '+356',
}, {
  code:   'MH',
  code3:  'MHL',
  name:   'Marshall Islands (the)',
  number: '584',
  phone:  '+692',
}, {
  code:   'MQ',
  code3:  'MTQ',
  name:   'Martinique',
  number: '474',
  phone:  '+596',
}, {
  code:   'MR',
  code3:  'MRT',
  name:   'Mauritania',
  number: '478',
  phone:  '+222',
}, {
  code:   'MU',
  code3:  'MUS',
  name:   'Mauritius',
  number: '480',
  phone:  '+230',
}, {
  code:   'YT',
  code3:  'MYT',
  name:   'Mayotte',
  number: '175',
  phone:  '+262',
}, {
  code:   'MX',
  code3:  'MEX',
  name:   'Mexico',
  number: '484',
  phone:  '+52',
}, {
  code:   'FM',
  code3:  'FSM',
  name:   'Micronesia (Federated States of)',
  number: '583',
  phone:  '+691',
}, {
  code:   'MD',
  code3:  'MDA',
  name:   'Moldova (the Republic of)',
  number: '498',
  phone:  '+373',
}, {
  code:   'MC',
  code3:  'MCO',
  name:   'Monaco',
  number: '492',
  phone:  '+377',
}, {
  code:   'MN',
  code3:  'MNG',
  name:   'Mongolia',
  number: '496',
  phone:  '+976',
}, {
  code:   'ME',
  code3:  'MNE',
  name:   'Montenegro',
  number: '499',
  phone:  '+382',
}, {
  code:   'MS',
  code3:  'MSR',
  name:   'Montserrat',
  number: '500',
  phone:  '+1-664',
}, {
  code:   'MA',
  code3:  'MAR',
  name:   'Morocco',
  number: '504',
  phone:  '+212',
}, {
  code:   'MZ',
  code3:  'MOZ',
  name:   'Mozambique',
  number: '508',
  phone:  '+258',
}, {
  code:   'MM',
  code3:  'MMR',
  name:   'Myanmar',
  number: '104',
  phone:  '+95',
}, {
  code:   'NA',
  code3:  'NAM',
  name:   'Namibia',
  number: '516',
  phone:  '+264',
}, {
  code:   'NR',
  code3:  'NRU',
  name:   'Nauru',
  number: '520',
  phone:  '+674',
}, {
  code:   'NP',
  code3:  'NPL',
  name:   'Nepal',
  number: '524',
  phone:  '+977',
}, {
  code:   'NL',
  code3:  'NLD',
  name:   'Netherlands (the)',
  number: '528',
  phone:  '+31',
}, {
  code:   'NC',
  code3:  'NCL',
  name:   'New Caledonia',
  number: '540',
  phone:  '+687',
}, {
  code:   'NZ',
  code3:  'NZL',
  name:   'New Zealand',
  number: '554',
  phone:  '+64',
}, {
  code:   'NI',
  code3:  'NIC',
  name:   'Nicaragua',
  number: '558',
  phone:  '+505',
}, {
  code:   'NE',
  code3:  'NER',
  name:   'Niger (the)',
  number: '562',
  phone:  '+227',
}, {
  code:   'NG',
  code3:  'NGA',
  name:   'Nigeria',
  number: '566',
  phone:  '+234',
}, {
  code:   'NU',
  code3:  'NIU',
  name:   'Niue',
  number: '570',
  phone:  '+683',
}, {
  code:   'NF',
  code3:  'NFK',
  name:   'Norfolk Island',
  number: '574',
  phone:  '+672',
}, {
  code:   'MP',
  code3:  'MNP',
  name:   'Northern Mariana Islands (the)',
  number: '580',
  phone:  '+1-670',
}, {
  code:   'NO',
  code3:  'NOR',
  name:   'Norway',
  number: '578',
  phone:  '+47',
}, {
  code:   'OM',
  code3:  'OMN',
  name:   'Oman',
  number: '512',
  phone:  '+968',
}, {
  code:   'PK',
  code3:  'PAK',
  name:   'Pakistan',
  number: '586',
  phone:  '+92',
}, {
  code:   'PW',
  code3:  'PLW',
  name:   'Palau',
  number: '585',
  phone:  '+680',
}, {
  code:   'PS',
  code3:  'PSE',
  name:   'Palestine, State of',
  number: '275',
  phone:  '+970',
}, {
  code:   'PA',
  code3:  'PAN',
  name:   'Panama',
  number: '591',
  phone:  '+507',
}, {
  code:   'PG',
  code3:  'PNG',
  name:   'Papua New Guinea',
  number: '598',
  phone:  '+675',
}, {
  code:   'PY',
  code3:  'PRY',
  name:   'Paraguay',
  number: '600',
  phone:  '+595',
}, {
  code:   'PE',
  code3:  'PER',
  name:   'Peru',
  number: '604',
  phone:  '+51',
}, {
  code:   'PH',
  code3:  'PHL',
  name:   'Philippines (the)',
  number: '608',
  phone:  '+63',
}, {
  code:   'PN',
  code3:  'PCN',
  name:   'Pitcairn',
  number: '612',
  phone:  '+870',
}, {
  code:   'PL',
  code3:  'POL',
  name:   'Poland',
  number: '616',
  phone:  '+48',
}, {
  code:   'PT',
  code3:  'PRT',
  name:   'Portugal',
  number: '620',
  phone:  '+351',
}, {
  code:   'PR',
  code3:  'PRI',
  name:   'Puerto Rico',
  number: '630',
  phone:  '+1',
}, {
  code:   'QA',
  code3:  'QAT',
  name:   'Qatar',
  number: '634',
  phone:  '+974',
}, {
  code:   'MK',
  code3:  'MKD',
  name:   'Republic of North Macedonia',
  number: '807',
  phone:  '+389',
}, {
  code:   'RO',
  code3:  'ROU',
  name:   'Romania',
  number: '642',
  phone:  '+40',
}, {
  code:   'RU',
  code3:  'RUS',
  name:   'Russian Federation (the)',
  number: '643',
  phone:  '+7',
}, {
  code:   'RW',
  code3:  'RWA',
  name:   'Rwanda',
  number: '646',
  phone:  '+250',
}, {
  code:   'RE',
  code3:  'REU',
  name:   'Réunion',
  number: '638',
  phone:  '+262',
}, {
  code:   'BL',
  code3:  'BLM',
  name:   'Saint Barthélemy',
  number: '652',
  phone:  '+590',
}, {
  code:   'SH',
  code3:  'SHN',
  name:   'Saint Helena, Ascension and Tristan da Cunha',
  number: '654',
  phone:  '+290',
}, {
  code:   'KN',
  code3:  'KNA',
  name:   'Saint Kitts and Nevis',
  number: '659',
  phone:  '+1-869',
}, {
  code:   'LC',
  code3:  'LCA',
  name:   'Saint Lucia',
  number: '662',
  phone:  '+1-758',
}, {
  code:   'MF',
  code3:  'MAF',
  name:   'Saint Martin (French part)',
  number: '663',
  phone:  '+590',
}, {
  code:   'PM',
  code3:  'SPM',
  name:   'Saint Pierre and Miquelon',
  number: '666',
  phone:  '+508',
}, {
  code:   'VC',
  code3:  'VCT',
  name:   'Saint Vincent and the Grenadines',
  number: '670',
  phone:  '+1-784',
}, {
  code:   'WS',
  code3:  'WSM',
  name:   'Samoa',
  number: '882',
  phone:  '+685',
}, {
  code:   'SM',
  code3:  'SMR',
  name:   'San Marino',
  number: '674',
  phone:  '+378',
}, {
  code:   'ST',
  code3:  'STP',
  name:   'Sao Tome and Principe',
  number: '678',
  phone:  '+239',
}, {
  code:   'SA',
  code3:  'SAU',
  name:   'Saudi Arabia',
  number: '682',
  phone:  '+966',
}, {
  code:   'SN',
  code3:  'SEN',
  name:   'Senegal',
  number: '686',
  phone:  '+221',
}, {
  code:   'RS',
  code3:  'SRB',
  name:   'Serbia',
  number: '688',
  phone:  '+381',
}, {
  code:   'SC',
  code3:  'SYC',
  name:   'Seychelles',
  number: '690',
  phone:  '+248',
}, {
  code:   'SL',
  code3:  'SLE',
  name:   'Sierra Leone',
  number: '694',
  phone:  '+232',
}, {
  code:   'SG',
  code3:  'SGP',
  name:   'Singapore',
  number: '702',
  phone:  '+65',
}, {
  code:   'SX',
  code3:  'SXM',
  name:   'Sint Maarten (Dutch part)',
  number: '534',
  phone:  '+1-721',
}, {
  code:   'SK',
  code3:  'SVK',
  name:   'Slovakia',
  number: '703',
  phone:  '+421',
}, {
  code:   'SI',
  code3:  'SVN',
  name:   'Slovenia',
  number: '705',
  phone:  '+386',
}, {
  code:   'SB',
  code3:  'SLB',
  name:   'Solomon Islands',
  number: '090',
  phone:  '+677',
}, {
  code:   'SO',
  code3:  'SOM',
  name:   'Somalia',
  number: '706',
  phone:  '+252',
}, {
  code:   'ZA',
  code3:  'ZAF',
  name:   'South Africa',
  number: '710',
  phone:  '+27',
}, {
  code:   'GS',
  code3:  'SGS',
  name:   'South Georgia and the South Sandwich Islands',
  number: '239',
  phone:  '+500',
}, {
  code:   'SS',
  code3:  'SSD',
  name:   'South Sudan',
  number: '728',
  phone:  '+211',
}, {
  code:   'ES',
  code3:  'ESP',
  name:   'Spain',
  number: '724',
  phone:  '+34',
}, {
  code:   'LK',
  code3:  'LKA',
  name:   'Sri Lanka',
  number: '144',
  phone:  '+94',
}, {
  code:   'SD',
  code3:  'SDN',
  name:   'Sudan (the)',
  number: '729',
  phone:  '+249',
}, {
  code:   'SR',
  code3:  'SUR',
  name:   'Suriname',
  number: '740',
  phone:  '+597',
}, {
  code:   'SJ',
  code3:  'SJM',
  name:   'Svalbard and Jan Mayen',
  number: '744',
  phone:  '+47',
}, {
  code:   'SE',
  code3:  'SWE',
  name:   'Sweden',
  number: '752',
  phone:  '+46',
}, {
  code:   'CH',
  code3:  'CHE',
  name:   'Switzerland',
  number: '756',
  phone:  '+41',
}, {
  code:   'SY',
  code3:  'SYR',
  name:   'Syrian Arab Republic',
  number: '760',
  phone:  '+963',
}, {
  code:   'TW',
  code3:  'TWN',
  name:   'Taiwan',
  number: '158',
  phone:  '+886',
}, {
  code:   'TJ',
  code3:  'TJK',
  name:   'Tajikistan',
  number: '762',
  phone:  '+992',
}, {
  code:   'TZ',
  code3:  'TZA',
  name:   'Tanzania, United Republic of',
  number: '834',
  phone:  '+255',
}, {
  code:   'TH',
  code3:  'THA',
  name:   'Thailand',
  number: '764',
  phone:  '+66',
}, {
  code:   'TL',
  code3:  'TLS',
  name:   'Timor-Leste',
  number: '626',
  phone:  '+670',
}, {
  code:   'TG',
  code3:  'TGO',
  name:   'Togo',
  number: '768',
  phone:  '+228',
}, {
  code:   'TK',
  code3:  'TKL',
  name:   'Tokelau',
  number: '772',
  phone:  '+690',
}, {
  code:   'TO',
  code3:  'TON',
  name:   'Tonga',
  number: '776',
  phone:  '+676',
}, {
  code:   'TT',
  code3:  'TTO',
  name:   'Trinidad and Tobago',
  number: '780',
  phone:  '+1-868',
}, {
  code:   'TN',
  code3:  'TUN',
  name:   'Tunisia',
  number: '788',
  phone:  '+216',
}, {
  code:   'TR',
  code3:  'TUR',
  name:   'Turkey',
  number: '792',
  phone:  '+90',
}, {
  code:   'TM',
  code3:  'TKM',
  name:   'Turkmenistan',
  number: '795',
  phone:  '+993',
}, {
  code:   'TC',
  code3:  'TCA',
  name:   'Turks and Caicos Islands (the)',
  number: '796',
  phone:  '+1-649',
}, {
  code:   'TV',
  code3:  'TUV',
  name:   'Tuvalu',
  number: '798',
  phone:  '+688',
}, {
  code:   'UG',
  code3:  'UGA',
  name:   'Uganda',
  number: '800',
  phone:  '+256',
}, {
  code:   'UA',
  code3:  'UKR',
  name:   'Ukraine',
  number: '804',
  phone:  '+380',
}, {
  code:   'AE',
  code3:  'ARE',
  name:   'United Arab Emirates (the)',
  number: '784',
  phone:  '+971',
}, {
  code:   'GB',
  code3:  'GBR',
  name:   'United Kingdom of Great Britain and Northern Ireland (the)',
  number: '826',
  phone:  '+44',
}, {
  code:   'UM',
  code3:  'UMI',
  name:   'United States Minor Outlying Islands (the)',
  number: '581',
  phone:  undefined,
}, {
  code:   'US',
  code3:  'USA',
  name:   'United States of America (the)',
  number: '840',
  phone:  '+1',
}, {
  code:   'UY',
  code3:  'URY',
  name:   'Uruguay',
  number: '858',
  phone:  '+598',
}, {
  code:   'UZ',
  code3:  'UZB',
  name:   'Uzbekistan',
  number: '860',
  phone:  '+998',
}, {
  code:   'VU',
  code3:  'VUT',
  name:   'Vanuatu',
  number: '548',
  phone:  '+678',
}, {
  code:   'VE',
  code3:  'VEN',
  name:   'Venezuela (Bolivarian Republic of)',
  number: '862',
  phone:  '+58',
}, {
  code:   'VN',
  code3:  'VNM',
  name:   'Viet Nam',
  number: '704',
  phone:  '+84',
}, {
  code:   'VG',
  code3:  'VGB',
  name:   'Virgin Islands (British)',
  number: '092',
  phone:  '+1-284',
}, {
  code:   'VI',
  code3:  'VIR',
  name:   'Virgin Islands (U.S.)',
  number: '850',
  phone:  '+1-340',
}, {
  code:   'WF',
  code3:  'WLF',
  name:   'Wallis and Futuna',
  number: '876',
  phone:  '+681',
}, {
  code:   'EH',
  code3:  'ESH',
  name:   'Western Sahara',
  number: '732',
  phone:  '+212',
}, {
  code:   'YE',
  code3:  'YEM',
  name:   'Yemen',
  number: '887',
  phone:  '+967',
}, {
  code:   'ZM',
  code3:  'ZMB',
  name:   'Zambia',
  number: '894',
  phone:  '+260',
}, {
  code:   'ZW',
  code3:  'ZWE',
  name:   'Zimbabwe',
  number: '716',
  phone:  '+263',
}, {
  code:   'AX',
  code3:  'ALA',
  name:   'Åland Islands',
  number: '248',
  phone:  '+358',
}]

export default countryListAllIsoData

/*

function getPhone(code) {
  for(let i=0; i<countries.length; i++){
    if(countries[i].code === code){
      return '+'+countries[i].phone
    }
  }
}
let countryListAllIsoData2 = countryListAllIsoData.map((obj) => {
  let phonePrefix = getPhone(obj.code)
  return Object.assign(obj, {phone: phonePrefix})
})

console.log(countryListAllIsoData2)

*/
