import themeColors from '@/assets/theme/colors'

const classes = {
  section: {
    display: 'grid',
  },
  subsection: {
    display:    'flex',
    alignItems: 'center',
  },
  typoB1: {
    color:      themeColors.gray.mid,
    fontWeight: 400,
  },
}

export default classes
