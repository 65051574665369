import themeColors from '@/assets/theme/colors'

const classes = {
  section: {
    display:        'grid',
    justifyContent: 'center',
    paddingTop:     '3rem',
    paddingBottom:  '5rem',
    paddingLeft:    '1.2rem',
    paddingRight:   '1.2rem',
    background:     themeColors.kroemtech.background,
  },
  typoTitle: {
    color: themeColors.gray.mid,
  },
  cardBox: {
    display: 'grid',
    zIndex:  2,
  },
  card: {
    display:      'flex',
    borderRadius: '10px',
  },
  typoB1: {
    color:  themeColors.gray.mid,
    margin: '15px',
  },
  numIcon: {
    color:         themeColors.bg.start,
    verticalAlign: 'middle',
    marginRight:   '10px',
    marginBottom:  '5px',
    fontSize:      '2rem',
  },
}

export default classes
