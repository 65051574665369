// React, Next
import { useTranslation } from 'next-i18next'
import {
  useContext, useEffect, useState,
  useRef,
} from 'react'
import { StaticImport } from 'next/dist/shared/lib/get-img-props'
import Image from 'next/image'

// MUI
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

// Custom
import { GlobalContext } from 'context/GlobalContextProvider'

// Img
import RealTime from 'public/img/index/worker_holding_tablet_w700.webp'
import Custom from 'public/img/index/sensor_pcb_w700.webp'
import Data from 'public/img/index/server_w750.webp'
import Interative from 'public/img/index/hands_holding_tablet_w700.webp'
import IntegrationSystems from 'public/img/services/integration_with_other_systems_w1200.webp'

// Types
import { SectionKey } from '@/types/global_types'

// Style
import themeColors from 'assets/theme/colors'
import breakpoints from 'assets/theme/breakpoints'
import classes from './OurServicesSection-CSS'

type Props = {
  scrollToSection: (section: SectionKey) => void,
};

export default function OurServicesSection(props:Props) {

  const { t } = useTranslation('services')
  const { screenDimensions } = useContext(GlobalContext)

  const [mobileMode, setMobileMode] = useState(false)
  useEffect(() => { setMobileMode(screenDimensions.width <= breakpoints.desktopToMobile) }, [screenDimensions])

  const getFontH4 = () => {
    const w = screenDimensions.width
    if (w > breakpoints.desktopLarge) return '1.2rem'
    if (w > breakpoints.desktopSmall) return '1.125rem'
    if (w > breakpoints.desktopToMobile) return '1.125rem'
    return '1rem'
  }

  const ref:any = useRef()

  const getFontH3 = () => {
    const w = screenDimensions.width
    if (w > breakpoints.desktopLarge) return '2rem'
    if (w > breakpoints.desktopSmall) return '1.75rem'
    if (w > breakpoints.desktopToMobile) return '1.625rem'
    if (w > breakpoints.mobileSmall) return '1.5rem'
    return '1.25rem'
  }

  const getPaddingLeft = () => {
    const w = screenDimensions.width
    if (w > breakpoints.desktopMid) return `${screenDimensions.width / 8}px`
    if (w > breakpoints.desktopSmall) return `${screenDimensions.width / 12}px`
    if (w > breakpoints.mobileSmall) return `${screenDimensions.width / 20}px`
    return 0
  }

  const getPadding = () => {
    const w = screenDimensions.width
    if (w > breakpoints.desktopMid) return `${screenDimensions.width / 16}px`
    if (w > breakpoints.desktopSmall) return `${screenDimensions.width / 20}px`
    if (w > breakpoints.desktopToMobile) return `${screenDimensions.width / 30}px`
    return '0px'
  }

  const getGrid4 = () => {
    const w = screenDimensions.width
    if (w > breakpoints.desktopLarge) return 'span 12'
    if (w > breakpoints.desktopMid) return 'span 15'
    if (w > breakpoints.desktopToMobile) return 'span 20'
    if (w > breakpoints.mobileMid) return 'span 30'
    return 'span 60'
  }

  const getSectionMarginTop = () => {
    const w = screenDimensions.width
    if (w > breakpoints.desktopLarge) return '8rem'
    if (w > breakpoints.desktopMid) return '7rem'
    if (w > breakpoints.desktopSmall) return '7rem'
    if (w > breakpoints.desktopToMobile) return '7rem'
    return '4rem'
  }

  const getImageHeight = () => {
    try {
      return ref.current.getBoundingClientRect().width / 2.5
    }
    catch { return 150 }
  }

  // *** Section ***
  const getCard = (p:{
    id   : number
    title: string,
    img            : string | StaticImport,
    alt            : string,
    SectionKey     : SectionKey
  }) => (
    <Box
      gridColumn = {getGrid4()}
      onClick = {() => props.scrollToSection(p.SectionKey)}
      sx = {classes.cardBox}
    >
      <Typography
        variant = "h4"
        fontSize = {getFontH4()}
        style = {classes.typeH4}
        sx = {{
          color: themeColors.gray.mid,
        }}
      >
        {p.title}
      </Typography>
      <Image
        alt = {p.alt}
        src = {p.img}
        style = {{
          width:        '100%',
          height:       getImageHeight(),
          objectFit:    'cover',
          borderRadius: '10px',
        }}
        priority = {false}
      />

    </Box>
  )

  const getSection = () => (
    <>
      <Box
        style = {{
          paddingLeft: getPaddingLeft(),
          zIndex:      2,
        }}
      >
        <Typography
          variant = "h3"
          fontSize = {getFontH3()}
          style = {{
            color:        themeColors.gray.mid,
            textAlign:    screenDimensions.width < breakpoints.mobileSmall ? 'center' : 'left',
            fontWeight:   400,
            marginBottom: '2.5rem',
          }}
        >
          {t('services.Our.title')}
        </Typography>
      </Box>
      <Box
        gridTemplateColumns = "repeat(60, 1fr)"
        sx = {classes.gridBox}
        style = {{
          paddingLeft:  getPadding(),
          paddingRight: getPadding(),
        }}
      >
        {
          getCard({
            id:         1,
            title:      t('services.Our.card1'),
            alt:        'RealTime',
            img:        RealTime,
            SectionKey: 'realTime',
          })
        }
        {
          getCard({
            id:         2,
            title:      t('services.Our.card2'),
            alt:        'Custom',
            img:        Custom,
            SectionKey: 'dataCollection',
          })
        }
        {
          getCard({
            id:         3,
            title:      t('services.Our.card3'),
            alt:        'Data',
            img:        Data,
            SectionKey: 'warehousing',
          })
        }
        {
          getCard({
            id:         4,
            title:      t('services.Our.card4'),
            alt:        'Logistics',
            img:        Interative,
            SectionKey: 'analytics',
          })
        }
        {
          getCard({
            id:         5,
            title:      t('services.Our.card5'),
            alt:        'Interative',
            img:        IntegrationSystems,
            SectionKey: 'integratonSystems',
          })
        }
      </Box>
    </>
  )

  // *** MAIN RENDER ***
  return (

    <Box
      component = "section"
      style = {{
        ...classes.section,
        marginTop:    getSectionMarginTop(),
        marginBottom: getSectionMarginTop(),
      }}
    >
      {getSection()}
    </Box>

  )
}
