// React, Next
import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import { useContext, useEffect, useState } from 'react'

// MUI
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import VerifiedSharpIcon from '@mui/icons-material/VerifiedSharp'

// Custom
import { GlobalContext } from 'context/GlobalContextProvider'
import AlignedText from '@/components/alignedText/AlignedText'

// Img
import WelcomeImage from 'public/img/index/excellence.webp'

// Style
import themeColors from 'assets/theme/colors'
import breakpoints from 'assets/theme/breakpoints'
import classes from './SuccessSection-CSS'

export default function SuccessSection() {

  const { t } = useTranslation('index')

  const { screenDimensions } = useContext(GlobalContext)

  const [mobileMode, setMobileMode] = useState(false)

  useEffect(() => { setMobileMode(screenDimensions.width <= breakpoints.desktopToMobile) }, [screenDimensions])

  const getFontH1 = () => {
    const w = screenDimensions.width

    if (w > breakpoints.desktopLarge) return '2.3rem'
    if (w > breakpoints.desktopSmall) return '2.1rem'
    if (w > breakpoints.mobileMid) return '2rem'
    if (w > breakpoints.mobileSmall) return '1.7rem'
    return '1.625rem'
  }

  const getPaddingLeft = () => {
    const w = screenDimensions.width
    if (w > breakpoints.desktopMid) return `${screenDimensions.width / 15}px`
    if (w > breakpoints.desktopSmall) return `${screenDimensions.width / 20}px`
    if (w > breakpoints.desktopToMobile) return `${screenDimensions.width / 25}px`
    return undefined
  }

  const getSectionMarginTop = () => {
    const w = screenDimensions.width
    if (w > breakpoints.desktopLarge) return '10rem'
    if (w > breakpoints.desktopMid) return '8rem'
    if (w > breakpoints.desktopSmall) return '7rem'
    if (w > breakpoints.desktopToMobile) return '6rem'
    return '3rem'
  }

  const getGrid = () => {
    const w = screenDimensions.width
    if (w > breakpoints.desktopToMobile) return 'span 6'
    return 'span 12'
  }

  const getBulletPadding = () => {
    const w = screenDimensions.width
    if (mobileMode && w > breakpoints.mobileMid) return '3rem'
    if (mobileMode) return '1rem'
    return undefined
  }

  const getImgSize = () => {
    if (screenDimensions.width > breakpoints.desktopLarge) {
      return {
        width: 600, height: 600,
      }
    }
    if (!mobileMode) {
      return {
        width: 450, height: 450,
      }
    }
    return {
      width: screenDimensions.width, height: screenDimensions.width / 2.6,
    }
  }

  const getImg = () => (
    <Box
      gridColumn = {getGrid()}
      sx = {classes.subsection}
      style = {{
        justifyContent: !mobileMode ? 'left' : 'center',
        paddingLeft:    !mobileMode ? getPaddingLeft() : '0px',
        marginBottom:   !mobileMode ? 0 : '3rem',
      }}
    >
      <Image
        alt       = "Kroemtech"
        src       = {WelcomeImage}
        style = {{
          borderRadius: mobileMode ? 0 : '5px',
          width:        getImgSize().width,
          height:       getImgSize().height,
          opacity:      0.9,
          boxShadow:    mobileMode
            ? 'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px'
            : 'rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px',
          objectPosition: mobileMode ? '80% 15%' : '80% 50%',
          objectFit:      'cover',
        }}
        priority = {false}
      />
    </Box>
  )

  // *** SECTION ***
  const getSection = () => (
    <>
      <Box
        gridColumn = {getGrid()}
        sx         = {classes.subsection}
        style      = {{
          paddingLeft:    getPaddingLeft(),
          marginBottom:   mobileMode ? '3.125rem' : 0,
          justifyContent: !mobileMode ? 'left' : 'center',
        }}
      >
        <Box
          sx = {classes.subsectionDiv}
        >
          <Typography
            variant  = "h1"
            fontSize = {getFontH1()}
            style    = {{
              ...classes.titleTypo,
              textAlign: !mobileMode ? 'left' : 'center',
              color:     themeColors.bg.start,
            }}
          >
            {t('success.title')}
          </Typography>

          {mobileMode ? getImg() : null}
          <Box
            sx = {{
              paddingLeft:  getBulletPadding(),
              paddingRight: getBulletPadding(),
            }}
          >
            <AlignedText
              icon = {(
                <VerifiedSharpIcon
                  sx = {{
                    color: themeColors.bg.start,
                  }}
                />
            )}
              boldText    = {t('success.subtitleBold1')}
              description = {t('success.subtitle1')}
              textColor   = {themeColors.gray.mid}
            />
            <AlignedText
              icon = {(
                <VerifiedSharpIcon
                  sx = {{
                    color: themeColors.bg.start,
                  }}
                />
            )}
              boldText    = {t('success.subtitleBold2')}
              description = {t('success.subtitle2')}
              textColor   = {themeColors.gray.mid}
            />
            <AlignedText
              icon = {(
                <VerifiedSharpIcon
                  sx = {{
                    color: themeColors.bg.start,
                  }}
                />
            )}
              boldText    = {t('success.subtitleBold3')}
              description = {t('success.subtitle3')}
              textColor   = {themeColors.gray.mid}
            />
            <AlignedText
              icon = {(
                <VerifiedSharpIcon
                  sx = {{
                    color: themeColors.bg.start,
                  }}
                />
            )}
              boldText    = {t('success.subtitleBold4')}
              description = {t('success.subtitle4')}
              textColor   = {themeColors.gray.mid}
            />
          </Box>
        </Box>
      </Box>
      {!mobileMode ? getImg() : null}

    </>
  )

  // *** MAIN RENDER ***
  return (

    <Box
      component = "section"
      style     = {{
        ...classes.section,
        marginTop: getSectionMarginTop(),
      }}
      gridTemplateColumns = "repeat(12, 1fr)"
    >
      {getSection()}
    </Box>

  )
}
