import themeColors from '@/assets/theme/colors'

const classes = {
  linkBox: {
    alignItems:     'center',
    justifyContent: 'center',
    color:          themeColors.gray.dark,
    '&:hover':      {
      opacity: 0.7,
    },
    '&:active': {
      opacity: 0.85,
    },
  },
  typoLink: {
    textDecoration: 'none',
  },
  mainDiv: {
    display:        'flex',
    justifyContent: 'center',

  },
  sndDiv: {
    maxWidth: 800,
    padding:  '1.25rem',
  },
  h1: {
    marginTop:    '2rem',
    marginBottom: '2.5rem',
    color:        themeColors.gray.dark,
  },
  h2desk: {
    marginTop:    '0.5rem',
    marginBottom: '1rem',
    color:        themeColors.gray.mid,
    fontSize:     '1.25rem',
  },
  body1desk: {
    marginTop: '0.5rem',
    fontSize:  '0.93rem',
    color:     themeColors.gray.mid,
  },
  h2mob: {
    marginTop:    '0.5rem',
    marginBottom: '1rem',
    color:        themeColors.gray.mid,
    fontSize:     '1rem',
  },
  body1mob: {
    marginTop: '0.5rem',
    fontSize:  '0.75rem',
    color:     themeColors.gray.mid,
  },

}

export default classes
