// React, Next
import { useContext, ReactElement } from 'react'
import { useTranslation } from 'next-i18next'

// MUI
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

// Custom
import { GlobalContext } from 'context/GlobalContextProvider'

// Style
import breakpoints from 'assets/theme/breakpoints'
import classes from './PrivacyView-CSS'

export default function PrivacyView() {

  const { t } = useTranslation('privacy')

  const { screenDimensions } = useContext(GlobalContext)

  const h2 = (text:string|ReactElement) => (
    <Typography
      variant = "h2"
      sx = {classes.h2}
      style = {{
        fontSize: screenDimensions.width > breakpoints.mobileSmall ? '1.5rem' : '1.375rem',
      }}
    >
      {text}
    </Typography>
  )

  const b2 = (text:string|ReactElement) => (
    <Typography
      variant = "body1"
      sx = {classes.b2}
    >
      {text}
    </Typography>
  )
  const bullet = (text:string|ReactElement) => (
    <div style = {classes.bulletsDiv}>
      <Typography
        variant = "body1"
        sx = {Object.assign(classes.b1, classes.ml)}
      >
        <b>&#8226;</b>
      </Typography>
      <Typography
        variant = "body1"
        sx = {classes.b1}
      >
        {text}
      </Typography>
    </div>
  )

  return (
    <Box
      sx = {classes.mainDiv}
      style = {{
        marginTop: screenDimensions.width > breakpoints.desktopToMobile ? '7rem' : '3rem',
      }}
    >
      <Box
        sx = {classes.sndDiv}
        style = {{
          minHeight: screenDimensions.height - 220,
        }}
      >
        <Typography
          variant = "h1"
          sx = {classes.h1}
          style = {{
            fontSize: screenDimensions.width > breakpoints.mobileSmall ? '2.125rem' : '1.875rem',
          }}
        >
          {t('h1')}
        </Typography>
        <section>
          <Typography
            variant = "body1"
            sx = {classes.b1}
            style = {{ marginBottom: '2.5rem' }}
          >
            {t('update')}
          </Typography>

        </section>

        <section>
          {h2(t('policy.introduction.title'))}
          {b2(t('policy.introduction.body'))}
        </section>

        <section>
          {h2(t('policy.information.title'))}
          {b2(t('policy.information.body.1'))}
          {bullet(
            <>
              <b>{t('policy.information.body.2.header')}</b>
              {t('policy.information.body.2.description')}
            </>,
          )}
          {bullet(
            <>
              <b>{t('policy.information.body.3.header')}</b>
              {t('policy.information.body.3.description')}
            </>,
          )}
          {bullet(
            <>
              <b>{t('policy.information.body.4.header')}</b>
              {t('policy.information.body.4.description')}
            </>,
          )}
        </section>

        <section>
          {h2(t('policy.usage.title'))}
          {b2(t('policy.usage.body.1'))}
          {bullet(<>{t('policy.usage.body.2')}</>)}
          {bullet(<>{t('policy.usage.body.3')}</>)}
          {bullet(<>{t('policy.usage.body.4')}</>)}
          {bullet(<>{t('policy.usage.body.5')}</>)}
          {bullet(<>{t('policy.usage.body.6')}</>)}
        </section>

        <section>
          {h2(t('policy.disclosure.title'))}
          {b2(t('policy.disclosure.body.1'))}
          {bullet(<>{t('policy.disclosure.body.2')}</>)}
          {bullet(<>{t('policy.disclosure.body.3')}</>)}
          {bullet(<>{t('policy.disclosure.body.4')}</>)}
          {bullet(<>{t('policy.disclosure.body.5')}</>)}
        </section>

        <section>
          {h2(t('policy.choises.title'))}
          {b2(t('policy.choises.body.1'))}
          {bullet(<>{t('policy.choises.body.2')}</>)}
          {bullet(<>{t('policy.choises.body.3')}</>)}
          {bullet(<>{t('policy.choises.body.4')}</>)}
        </section>

        <section>
          {h2(t('policy.security.title'))}
          {b2(t('policy.security.body'))}
        </section>

        <section>
          {h2(t('policy.changes.title'))}
          {b2(t('policy.changes.body'))}
        </section>

        <section style = {{ marginBottom: '4rem' }}>
          {h2(t('policy.contact.title'))}
          {b2(t('policy.contact.body'))}
        </section>

      </Box>
    </Box>

  )
}
