import themeColors from '@/assets/theme/colors'

const classes = {
  section: {
    display:       'grid',
    paddingBottom: '5rem',
    paddingTop:    '3rem',
    background:    themeColors.info.lightest,
  },
  typoTitle: {
    color:        themeColors.gray.mid,
    marginBottom: '30px',
    paddingLeft:  '10px',
    paddingRight: '10px',
  },
  cardBox: {
    display: 'grid',
    zIndex:  2,
  },
  card: {
    display:      'flex',
    borderRadius: '10px',
  },
  typoB1: {
    color:      themeColors.gray.mid,
    margin:     '5px',
    fontWeight: 600,
  },
  typoB2: {
    color:      themeColors.gray.mid,
    fontWeight: 400,
  },
  numIcon: {
    color:         themeColors.bg.start,
    verticalAlign: 'middle',
    marginRight:   '10px',
    marginBottom:  '5px',
    fontSize:      '2rem',
  },
}

export default classes
