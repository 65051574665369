// React, Next
import { useTranslation } from 'next-i18next'
import {
  useContext, useEffect, useRef, useState,
} from 'react'
import { useRouter } from 'next/router'
import Image from 'next/image'
import Link from 'next/link'

// MUI
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import VerifiedSharpIcon from '@mui/icons-material/VerifiedSharp'

// Custom
import { GlobalContext } from 'context/GlobalContextProvider'
import AlignedText from '@/components/alignedText/AlignedText'

// Img
import Applications from 'public/img/applications/remote_sensor_in_a_crop_791654999.png'

// Config
import i18nextConfig from 'next-i18next.config'

// Style
import themeColors from 'assets/theme/colors'
import breakpoints from 'assets/theme/breakpoints'
import { translateHref } from '@/assets/functions/links'
import classes from './RealAplicationsSection-CSS'

export default function RealAplicationsSection() {

  const { t } = useTranslation('index')
  const router = useRouter()
  const currentLocale = router.query.locale || i18nextConfig.i18n.defaultLocale
  const { screenDimensions } = useContext(GlobalContext)

  const [mobileMode, setMobileMode] = useState(false)

  useEffect(() => { setMobileMode(screenDimensions.width <= breakpoints.desktopToMobile) }, [screenDimensions])

  const imgRef:any = useRef()
  const cardRef:any = useRef()

  const getFontH2 = () => {
    const w = screenDimensions.width
    if (w > breakpoints.desktopLarge) return '1.625rem'
    if (w > breakpoints.desktopSmall) return '1.5rem'
    if (w > breakpoints.desktopToMobile) return '1.375rem'
    if (w > breakpoints.mobileMid) return '1.25rem'
    return '1.125rem'
  }

  const getCardPosition = () => {
    const pos = {
      top:  '0',
      left: '0',
    }
    const w = screenDimensions.width

    let imgH = 0
    let cardH = 0

    try {
      imgH = imgRef.current.clientHeight
      cardH = cardRef.current.clientHeight
    }
    catch { /* empty */ }

    pos.top = `${(imgH - cardH) / 2}px`

    if (w > breakpoints.desktopLarge) pos.left = '300px'
    else if (w > breakpoints.desktopMid) pos.left = '180px'
    else if (w > breakpoints.desktopSmall) pos.left = '100px'
    else if (w > breakpoints.desktopToMobile) pos.left = '50px'
    else pos.left = '0px'

    return pos
  }

  const getImgHeight = () => {
    const w = screenDimensions.width

    if (w > breakpoints.desktopLarge) return 600
    if (w > breakpoints.desktopToMobile) return 500
    if (w > breakpoints.mobileSmall) return 500
    return 600
  }

  const getImgWidth = () => {
    try {
      return imgRef.current.getBoundingClientRect().width
    }
    catch {
      return screenDimensions.width * 0.6
    }
  }

  const getSectionMarginTop = () => {
    const w = screenDimensions.width
    if (w > breakpoints.desktopLarge) return '8rem'
    if (w > breakpoints.desktopMid) return '6rem'
    if (w > breakpoints.desktopSmall) return '6rem'
    if (w > breakpoints.desktopToMobile) return '5rem'
    return '5rem'
  }

  const getBullet = (text:string) => (
    <AlignedText
      icon = {(
        <VerifiedSharpIcon
          style = {classes.icon}
          sx = {{
            color: themeColors.gray.mid,
          }}
        />
            )}
      boldText = {text}
      textColor = {themeColors.gray.mid}
      itemMarginBottom = "0"
    />
  )

  // *** SECTION ***
  const getSection = () => (

    <Box
      ref = {cardRef}
      sx = {mobileMode ? classes.cardMobile : classes.card}
      style = {{
        left:      getCardPosition().left,
        marginTop: mobileMode ? '50px' : getCardPosition().top,
      }}
    >
      <Typography
        variant = "h2"
        fontSize = {getFontH2()}
        style = {{
          marginBottom: '1.875rem',
          textAlign:    !mobileMode ? 'left' : 'center',
          color:        themeColors.gray.mid,
          fontWeight:   'bold',
        }}
      >
        {t('aplications.title')}
      </Typography>

      {getBullet(t('aplications.subtitle4'))}
      {getBullet(t('aplications.subtitle2'))}
      {getBullet(t('aplications.subtitle5'))}
      {getBullet(t('aplications.subtitle6'))}
      {getBullet(t('aplications.subtitle7'))}
      {getBullet(t('aplications.subtitle1'))}
      {getBullet(t('aplications.subtitle9'))}
      {getBullet(t('aplications.subtitle3'))}

      <Link href = {translateHref(currentLocale, 'applications')}>
        <Box style = {classes.learnMoreBox}>
          <Typography
            variant = "body1"
            sx = {classes.learnMoreTypo}
          >
            {t('learnMore')}
            {' '}
            &#x279C;
          </Typography>
        </Box>
      </Link>

    </Box>

  )

  // *** MAIN RENDER ***
  return (
    <Box
      component = "section"
      sx = {mobileMode ? classes.sectionMobile : classes.section}
      style = {{
        marginTop: getSectionMarginTop(),
      }}
    >
      {
        mobileMode ? null
          : (
            <Box
              sx = {classes.greenBox}
            />
          )
      }
      {getSection()}
      <Box
        ref = {imgRef}
        sx = {mobileMode ? classes.imgBoxMobile : classes.imgBox}
      >
        <Image
          alt    = "Kroemtech"
          src    = "https://kroemtech-public.s3.eu-central-1.amazonaws.com/kroemtech_website/index/farming_sensor.webp"
          width  = {getImgWidth()}
          height = {getImgHeight()}
          style  = {{
            objectPosition: '100% 50%',
            objectFit:      'cover',
          }}
          priority = {false}
        />
      </Box>

    </Box>
  )
}
