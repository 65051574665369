const classes = {
  boxContent: {
    display:       'flex',
    flexDirection: 'column',
  },
  TitleContainer: {
    display: 'flex',
  },
  TitleTextContainer: {
    display:        'flex',
    justifyContent: 'left',
  },
}

export default classes
