import themeColors from '@/assets/theme/colors'

const classes = {
  section: {
    position: 'relative',
    width:    '100%',
    minWidth: '900px',
    maxWidth: '900px',
    height:   '580px',
  },
  boxImage: {
    position:      'absolute',
    display:       'flex',
    flexDirection: 'column',
    alignItems:    'center',
    '&:hover':     {
      cursor: 'pointer',
    },
  },
  boxText: {
    position:        'absolute',
    backgroundColor: themeColors.gray.lighter,
    padding:         '0.5rem 1rem 0.5rem 1rem',
    borderRadius:    50,
    width:           250,
    textAlign:       'center',
  },
  thinScrollbar: {
    scrollbarWidth:         'thin',
    '&::-webkit-scrollbar': {
      width:  '5px',
      height: '5px',
    },
    '&::-webkit-scrollbar-track': {
      background: '#ddd',
    },
    '&::-webkit-scrollbar-thumb': {
      background:   '#999',
      borderRadius: '5px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background:   '#777',
      borderRadius: '5px',
    },
  },
}

export default classes
