// React, Next
import {
  ReactNode, useContext, useEffect, useState,
} from 'react'
import Image, { StaticImageData } from 'next/image'

// MUI
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

// Custom
import { GlobalContext } from 'context/GlobalContextProvider'
import AlignedText from 'components/alignedText/AlignedText'

// Style
import breakpoints from 'assets/theme/breakpoints'
import classes from './CardSection-CSS'

type PropTypes = {
  title       : string;
  titleColor  : string;
  icon       ?: ReactNode;
  text       ?: string[];
  textColor   : string;
  description : string;
  image       : StaticImageData;
  imageAlt    : string;
  imgPosition?: string;
  module     ?: string
};
export default function CardSection(props: PropTypes) {
  const [mobileMode, setMobileMode] = useState(false)
  const { screenDimensions } = useContext(GlobalContext)
  useEffect(() => { setMobileMode(screenDimensions.width <= breakpoints.desktopToMobile) }, [screenDimensions])

  const getFontH1 = () => {
    const w = screenDimensions.width
    if (w > breakpoints.desktopLarge) return '1.625rem'
    if (w > breakpoints.desktopSmall) return '1.625rem'
    if (w > breakpoints.mobileMid) return '1.5rem'
    if (w > breakpoints.mobileSmall) return '1.4rem'
    return '1.2rem'
  }

  const getFontH5 = () => {
    const w = screenDimensions.width
    if (w > breakpoints.desktopLarge) return '1.1rem'
    if (w > breakpoints.desktopMid) return '1.1rem'
    if (w > breakpoints.desktopSmall) return '1.1rem'
    if (w > breakpoints.desktopToMobile) return '1rem'
    if (w > breakpoints.mobileLarge) return '1rem'
    if (w > breakpoints.mobileMid) return '1rem'
    if (w > breakpoints.mobileSmall) return '0.9rem'
    if (w > breakpoints.mobileSmaller) return '0.8rem'
    return '0.8rem'
  }

  const getGrid = () => {
    const w = screenDimensions.width
    if (w > breakpoints.desktopToMobile) return 'span 6'
    return 'span 12'
  }

  const getPhotoHeight = () => {
    const w = screenDimensions.width
    if (w > breakpoints.desktopToMobile) return 400
    return 300
  }

  const renderTextContent = () => (
    <Box
      gridColumn = {getGrid()}
      sx = {{
        ...classes.section1,
        marginBottom:   mobileMode ? '3.125rem' : 0,
        justifyContent: !mobileMode ? 'left' : 'center',
        marginTop:      props.module === '2' ? '1rem' : '1rem',
      }}
    >
      <Box
        style = {{
          ...classes.card,
          paddingLeft:  screenDimensions.width <= breakpoints.desktopToMobile ? '1rem' : 0,
          paddingRight: screenDimensions.width <= breakpoints.desktopToMobile ? '1rem' : 0,
        }}
      >
        <Typography
          variant  = "h1"
          fontSize = {getFontH1()}
          sx       = {{
            marginBottom: !mobileMode ? '2rem' : '2.5rem',
            textAlign:    !mobileMode ? 'left' : 'center',
            color:        props.titleColor,
            fontWeight:   'bold',
          }}
        >
          {props.title}
        </Typography>
        {props.text && (
          <>
            {props.text.map((text, index) => (
              <AlignedText
                key = {index}
                icon = {props.icon}
                boldText = {text}
                textColor = {props.textColor}
              />
            ))}
          </>
        )}
        <Typography
          variant = "h5"
          sx = {{
            marginBottom: !mobileMode ? '1.5rem' : 0,
            marginTop:    !mobileMode ? '2rem' : '1.5rem',
            textAlign:    'left',
            color:        props.textColor,
            fontWeight:   '400',
            fontSize:     getFontH5(),
          }}
        >
          {props.description}
        </Typography>
      </Box>
    </Box>
  )

  const renderImageContent = () => (
    <Box
      gridColumn = {getGrid()}
      sx         = {classes.card}
      style      = {{
        textAlign: !mobileMode ? 'right' : 'center',
      }}
    >
      <Image
        alt = {props.imageAlt}
        src = {props.image}
        style = {{
          borderRadius:   mobileMode ? 0 : '5px',
          width:          '100%',
          height:         getPhotoHeight(),
          objectFit:      'cover',
          objectPosition: props.imgPosition ?? '50% 50%',
        }}
        priority = {false}
      />
    </Box>
  )

  return (
    props.module === '2' && !mobileMode ? (
      <>
        {renderImageContent()}
        {renderTextContent()}
      </>
    ) : (
      <>
        {renderTextContent()}
        {renderImageContent()}
      </>
    )
  )
}
