/* eslint-disable import/prefer-default-export */
import { ApiResponse, BASE_URL } from '../ApiGlobalInterface'
import { ContactFormInterface } from './interfaces'

/**
 * @description Send contact form
 * @method POST
 * @link www.kroemtech.com/api/v1/contact/contactForm
 * @param formContent: ContactForm
 * @response
 *   ok: success ? true : false,
 *   statusCode: statusMessage
 *   if Success
 *     204: No content
 *     message: null
 *   if Error
 *     500: Internal Server Error
 *     message: errorMessage
 */
export const SendContactForm = async (formContent: ContactFormInterface): Promise<ApiResponse> => {
  try {
    const response = await fetch(`${BASE_URL}/contact/contactForm`, {
      method:  'POST',
      headers: {
        Accept:         'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formContent),
    })
    const message = response.ok
      ? null
      : (await response.json()).message
    return {
      ok:            response.ok,
      statusCode:    response.status,
      statusMessage: response.statusText,
      message,
    }
  }
  catch (e) {
    console.log(e)
    return {
      ok:            false,
      statusCode:    502,
      statusMessage: 'api error',
      message:       'error',
    }

  }
}
