export default function getSwitcherHref(hrefBase:string, btn:string, pName:string, target:string) {
  let href = target

  if (pName.includes('/technology') || pName.includes('/technologie')) {
    if (btn === 'en') href = `${hrefBase}technology`
    else if (btn === 'de') href = `${hrefBase}technologie`
  }
  else if (pName.includes('/services') || pName.includes('/dienstleistungen')) {
    if (btn === 'en') href = `${hrefBase}services`
    else if (btn === 'de') href = `${hrefBase}dienstleistungen`
  }
  else if (pName.includes('/applications') || pName.includes('/anwendungen')) {
    if (btn === 'en') href = `${hrefBase}applications`
    else if (btn === 'de') href = `${hrefBase}anwendungen`
  }
  else if (pName.includes('/unternehmen') || pName.includes('/about')) {
    if (btn === 'en') href = `${hrefBase}about`
    else if (btn === 'de') href = `${hrefBase}unternehmen`
  }
  else if (pName.includes('/kontakt') || pName.includes('/contact')) {
    if (btn === 'en') href = `${hrefBase}contact`
    else if (btn === 'de') href = `${hrefBase}kontakt`
  }
  else if (pName.includes('/datenschutz') || pName.includes('/privacy')) {
    if (btn === 'en') href = `${hrefBase}privacy`
    else if (btn === 'de') href = `${hrefBase}datenschutz`
  }

  // articles
  else if (pName.includes('/bedeutung-der-maschinendatenerfassung') || pName.includes('/importance-of-machine-data-collection')) {
    if (btn === 'en') href = `${hrefBase}articles/importance-of-machine-data-collection`
    else if (btn === 'de') href = `${hrefBase}artikel/bedeutung-der-maschinendatenerfassung`
  }
  else if (pName.includes('/herausforderungen-bei-der-maschinendatenerfassung') || pName.includes('/challenges-in-machine-data-collection')) {
    if (btn === 'en') href = `${hrefBase}articles/challenges-in-machine-data-collection`
    else if (btn === 'de') href = `${hrefBase}artikel/herausforderungen-bei-der-maschinendatenerfassung`
  }
  else if (pName.includes('/artikel') || pName.includes('/articles')) {
    if (btn === 'en') href = `${hrefBase}articles`
    else if (btn === 'de') href = `${hrefBase}artikel`
  }

  else href = hrefBase

  return href
}
