/* eslint-disable react/button-has-type */
// React, Next
import { useRouter } from 'next/router'
import Link from 'next/link'
import { useContext } from 'react'

// MUI
import Box from '@mui/material/Box'

// Custom
import languageDetector from 'lib/languageDetector'
import { GlobalContext } from 'context/GlobalContextProvider'

// Style
import breakpoints from '@/assets/theme/breakpoints'
import classes from './LanguageSwitcher-CSS'
import getSwitcherHref from './LanguageSwitcherRouter'

// eslint-disable-next-line react/prop-types
function LanguageSwitcher({ currentLocale, ...rest }: {
  [x: string]: any;
  currentLocale: string|string[];
}) {
  const router:any = useRouter()

  const { screenDimensions } = useContext(GlobalContext)

  const getHref = (btn:string) => {
    let href = rest.href || router.asPath
    const pName = router.pathname
    const hrefBase = `/${btn}/`

    href = getSwitcherHref(hrefBase, btn, pName, href)

    return href
  }

  const switchLang = (btn:string) => {
    if (btn !== currentLocale) {
      languageDetector.cache(btn)
    }
    return null
  }

  return (
    <Box
      sx = {classes.mainBox}
    >
      <Link
        href = {getHref('en')}
      >
        <Box
          sx = {classes.linkBox}
          style = {{
            fontSize: screenDimensions.width <= breakpoints.desktopToMobile ? 13 : 11,
          }}
          onClick = {() => switchLang('en')}
        >
          EN
        </Box>
      </Link>
      <div style = {classes.separator}>
        |
      </div>
      <Link
        href = {getHref('de')}
      >
        <Box
          sx = {classes.linkBox}
          style = {{
            fontSize: screenDimensions.width <= breakpoints.desktopToMobile ? 13 : 11,
          }}
          onClick = {() => switchLang('de')}
        >
          DE
        </Box>
      </Link>
    </Box>
  )
}

export default LanguageSwitcher
