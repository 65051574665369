// React, Next
import { useTranslation } from 'next-i18next'
import {
  useContext, useEffect, useState, useRef,
} from 'react'
import { StaticImport } from 'next/dist/shared/lib/get-img-props'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'

// MUI
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

// Custom
import { GlobalContext } from 'context/GlobalContextProvider'

// Img
import Manufacturing from 'public/img/index/manufacturing_industry_w600.webp'
import Energy from  'public/img/index/energy_industry_w575.webp'
import Logistics from 'public/img/index/logistics_industry_w550.webp'
import Chemical from 'public/img/index/chemical_industry_w700.webp'
import Agriculture from 'public/img/index/farming_industry_w600.webp'
import Food from 'public/img/index/food_industry_w550.webp'
import SmartCities from 'public/img/index/smart_city_w580.webp'
import Automations from 'public/img/index/building_automation_w800.webp'

// Config
import i18nextConfig from 'next-i18next.config'
import { translateHref } from '@/assets/functions/links'

// Style
import themeColors from 'assets/theme/colors'
import breakpoints from 'assets/theme/breakpoints'
import classes from './SolutionsSection-CSS'

export default function SolutionsSection() {
  const router = useRouter()
  const currentLocale = router.query.locale || i18nextConfig.i18n.defaultLocale

  const { t } = useTranslation('index')
  const { screenDimensions } = useContext(GlobalContext)

  const [isHovered, setIsHovered] = useState(-1)

  const [mobileMode, setMobileMode] = useState(false)
  useEffect(() => { setMobileMode(screenDimensions.width <= breakpoints.desktopToMobile) }, [screenDimensions])

  const getFontH4 = () => {
    const w = screenDimensions.width
    if (w > breakpoints.desktopLarge) return '1.25rem'
    if (w > breakpoints.desktopSmall) return '1.125rem'
    if (w > breakpoints.desktopToMobile) return '1.125rem'
    return '1rem'
  }

  const ref:any = useRef()

  const getFontH3 = () => {
    const w = screenDimensions.width
    if (w > breakpoints.desktopLarge) return '2rem'
    if (w > breakpoints.desktopSmall) return '1.75rem'
    if (w > breakpoints.desktopToMobile) return '1.625rem'
    if (w > breakpoints.mobileSmall) return '1.5rem'
    return '1.25rem'
  }

  const getPaddingLeft = () => {
    const w = screenDimensions.width
    if (w > breakpoints.desktopMid) return `${screenDimensions.width / 8}px`
    if (w > breakpoints.desktopSmall) return `${screenDimensions.width / 12}px`
    if (w > breakpoints.desktopToMobile) return `${screenDimensions.width / 20}px`
    return '0px'
  }

  const getPadding = () => {
    const w = screenDimensions.width
    if (w > breakpoints.desktopMid) return `${screenDimensions.width / 16}px`
    if (w > breakpoints.desktopSmall) return `${screenDimensions.width / 20}px`
    if (w > breakpoints.desktopToMobile) return `${screenDimensions.width / 30}px`
    return '0px'
  }

  const getGrid4 = () => {
    const w = screenDimensions.width
    if (w > breakpoints.desktopMid) return 'span 3'
    if (w > breakpoints.desktopToMobile) return 'span 4'
    if (w > breakpoints.mobileMid) return 'span 6'
    return 'span 12'
  }

  const getSectionMarginTop = () => {
    const w = screenDimensions.width
    if (w > breakpoints.desktopLarge) return '8rem'
    if (w > breakpoints.desktopMid) return '7rem'
    if (w > breakpoints.desktopSmall) return '7rem'
    if (w > breakpoints.desktopToMobile) return '7rem'
    return '4rem'
  }

  const getCardWidth = () => {
    const w = screenDimensions.width
    if (getGrid4() === 'span 12') {
      if (w > breakpoints.mobileMid) {
        return {
          max: screenDimensions.width - 80,
          set: screenDimensions.width - 80,
          min: screenDimensions.width - 80,
        }
      }
      return {
        max: screenDimensions.width - 55,
        set: screenDimensions.width - 55,
        min: screenDimensions.width - 55,
      }
    }
    return { max: 1000, set: undefined, min: 250 }
  }

  const getImageHeight = () => {
    try {
      return ref.current.getBoundingClientRect().width / 2.5
    }
    catch { return 150 }
  }

  // *** Section ***
  const getCard = (p:{
    id          : number
    title       : string,
    img         : string | StaticImport,
    alt         : string,
    color       : any,
    imgPosition?: string
  }) => (
    <Box
      onMouseEnter = {() => setIsHovered(p.id)}
      onMouseLeave = {() => setIsHovered(-1)}
      gridColumn   = {getGrid4()}
      sx = {{
        '&:hover': {
          cursor:     'default',
          transform:  'scale(1.03)',
          transition: 'transform 200ms',
        },
        maxWidth:      getCardWidth().max,
        minWidth:      getCardWidth().min,
        width:         getCardWidth().set,
        flexDirection: 'column',
      }}
      style = {classes.card}
    >
      <Typography
        variant  = "h4"
        fontSize = {getFontH4()}
        style    = {classes.typeH4}
        sx       = {{
          color: p.color,
        }}
      >
        {p.title}
      </Typography>
      <Image
        alt   = {p.alt}
        src   = {p.img}
        style = {{
          width:          '100%',
          height:         getImageHeight(),
          objectFit:      'cover',
          objectPosition: p.imgPosition,
          borderRadius:   '10px',
        }}
        priority = {false}
      />

    </Box>
  )

  const getSection = () => (
    <>
      <Box
        style = {{
          paddingLeft: !mobileMode ? getPaddingLeft() : '0px',
          zIndex:      2,
        }}
      >

        <Box
          sx = {{
            ...classes.titleBox,
            display: screenDimensions.width > breakpoints.mobileMid ? 'flex' : 'block',
          }}
        >
          <Typography
            variant  = "h3"
            fontSize = {getFontH3()}
            style    = {{
              color:      themeColors.gray.mid,
              textAlign:  !mobileMode ? 'left' : 'center',
              fontWeight: 'bold',
            }}
          >
            {t('solutions.title')}
          </Typography>
          <Link href = {translateHref(currentLocale, 'applications')}>
            <Box sx = {screenDimensions.width > breakpoints.mobileMid ? classes.learnHowBox : classes.learnHowBox2}>
              <Typography
                variant = "body1"
                sx = {classes.learnMoreTypo}
              >
                {t('LearnHow')}
                {' '}
                &#x279C;
              </Typography>
            </Box>
          </Link>
        </Box>

      </Box>
      <Box
        gridTemplateColumns = "repeat(12, 1fr)"
        sx                  = {classes.cardBox}
        style               = {{
          paddingLeft:  getPadding(),
          paddingRight: getPadding(),
        }}
      >
        {
          getCard({
            id:    1,
            title: t('solutions.subtitle1'),
            alt:   'Manufacturing',
            img:   Manufacturing,
            color: isHovered === 1 ? themeColors.gray.mid : undefined,
          })
        }
        {
          getCard({
            id:    2,
            title: t('solutions.subtitle2'),
            alt:   'Food',
            img:   Food,
            color: isHovered === 2 ? themeColors.gray.mid : undefined,
          })
        }
        {
          getCard({
            id:          3,
            title:       t('solutions.subtitle3'),
            alt:         'Chemical',
            img:         Chemical,
            color:       isHovered === 3 ? themeColors.gray.mid : undefined,
            imgPosition: '50% 60%',
          })
        }
        {
          getCard({
            id:          4,
            title:       t('solutions.subtitle4'),
            alt:         'Logistics',
            img:         Logistics,
            color:       isHovered === 4 ? themeColors.gray.mid : undefined,
            imgPosition: '50% 65%',
          })
        }
        {
          getCard({
            id:    5,
            title: t('solutions.subtitle5'),
            alt:   'Energy',
            img:   Energy,
            color: isHovered === 5 ? themeColors.gray.mid : undefined,
          })
        }
        {
          getCard({
            id:    6,
            title: t('solutions.subtitle6'),
            alt:   'Agriculture',
            img:   Agriculture,
            color: isHovered === 6 ? themeColors.gray.mid : undefined,
          })
        }
        {
          getCard({
            id:          7,
            title:       t('solutions.subtitle7'),
            alt:         'Buildings Automation',
            img:         Automations,
            color:       isHovered === 7 ? themeColors.gray.mid : undefined,
            imgPosition: '50% 60%',
          })
        }
        {
          getCard({
            id:          8,
            title:       t('solutions.subtitle8'),
            alt:         'Smart Cities',
            img:         SmartCities,
            color:       isHovered === 8 ? themeColors.gray.mid : undefined,
            imgPosition: '50% 55%',
          })
        }
      </Box>
    </>
  )

  // *** MAIN RENDER ***
  return (

    <Box
      component = "section"
      style = {{
        ...classes.section,
        marginTop: getSectionMarginTop(),
      }}
    >
      {getSection()}
    </Box>

  )
}
