const classes = {
  section: {
    display:             'grid',
    gridTemplateColumns: 'repeat(12, 1fr)',
    gap:                 '16px',
    justifyContent:      'center',
  },
  section1: {
    display: 'grid',
    gap:     '16px',
  },

  card: {
    width: '100%',
  },

}

export default classes
