import themeColors from '@/assets/theme/colors'

const classes = {
  section: {
    display:        'grid',
    justifyContent: 'center',
    paddingTop:     '4.375rem',
    paddingBottom:  '1.25rem',
  },
  subsection: {
    display:    'flex',
    alignItems: 'center',
  },
  subsectionDiv: {
    zIndex:       2,
    paddingLeft:  '1.875rem',
    paddingRight: '1.875rem',
  },
  icon: {
    width:        20,
    height:       20,
    marginRight:  '0.5rem',
    marginBottom: '-0.2rem',
  },
  learnMoreBox: {
    display:        'flex',
    justifyContent: 'right',
  },
  learnMoreTypo: {
    color:      themeColors.kroemtech.sensBlue,
    fontWeight: 600,
    fontSize:   '1rem',
    '&:hover':  {
      color: `${themeColors.kroemtech.sensBlue}cc`,
    },
  },
  mobileImg: {
    justifyContent: 'center',
    marginBottom:   '2rem',
  },
}

export default classes
