// React, Next
import { useTranslation } from 'next-i18next'
import { useContext } from 'react'

// MUI
import Box from '@mui/material/Box'
import VerifiedSharpIcon from '@mui/icons-material/VerifiedSharp'
import EngineeringIcon from '@mui/icons-material/Engineering'
import VerifiedUserSharpIcon from '@mui/icons-material/VerifiedUserSharp'
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium'

// Custom
import InsightsSharpIcon from '@mui/icons-material/InsightsSharp'
import ElectricRickshawSharpIcon from '@mui/icons-material/ElectricRickshawSharp'
import SettingsRemoteSharpIcon from '@mui/icons-material/SettingsRemoteSharp'
import { GlobalContext } from 'context/GlobalContextProvider'
import CardSection from '@/components/cards/cardSection/CardSection'
import CardSection2 from '@/components/cards/cardSection2/CardSection2'

// Img
import RealTime from 'public/img/services/worker_holding_tablet_w1350.webp'

// Style
import themeColors from 'assets/theme/colors'
import breakpoints from 'assets/theme/breakpoints'
import classes from './RealTimeSection-CSS'

export default function RealTimeSection() {

  const { t } = useTranslation('services')

  const { screenDimensions } = useContext(GlobalContext)

  const getSectionMarginTop = () => {
    const w = screenDimensions.width
    if (w > breakpoints.desktopLarge) return '6rem'
    if (w > breakpoints.desktopMid) return '4rem'
    if (w > breakpoints.desktopSmall) return '4rem'
    if (w > breakpoints.desktopToMobile) return '5rem'
    if (w > breakpoints.mobileMid) return '5rem'
    if (w > breakpoints.mobileSmall) return '5rem'
    return '5rem'
  }

  const getSectionPadding = () => {
    const w = screenDimensions.width
    if (w > breakpoints.desktopLarge) return '12rem'
    if (w > breakpoints.desktopMid) return '6rem'
    if (w > breakpoints.desktopToMobile) return '3rem'
    return '1rem'
  }

  // *** SECTION ***
  const getSection = () => (
    <CardSection
      title = {t('cards.01.title')}
      titleColor = {themeColors.bg.start}
      icon = {(
        <VerifiedSharpIcon sx = {{ color: themeColors.bg.start }} />
      )}
      text = {[
        t('cards.01.subtitle.01'),
        t('cards.01.subtitle.02'),
        t('cards.01.subtitle.03'),
      ]}
      textColor   = {themeColors.gray.mid}
      description = {t('cards.01.description')}
      image       = {RealTime}
      imageAlt    = "Kroemtech"
      imgPosition = "100% 50%"
    />
  )
  const getSection2 = () => (
    <CardSection2
      title = {t('cards.benefitsText')}
      titleColor = {themeColors.bg.start}
      alignedTexts = {[
        {
          boldText: t('cards.01.benefits.bold01'),
          text:     t('cards.01.benefits.01'),
          icon:     <VerifiedUserSharpIcon sx = {{ color: themeColors.bg.start }} />,
        },
        {
          boldText: t('cards.01.benefits.bold02'),
          text:     t('cards.01.benefits.02'),
          icon:     <EngineeringIcon sx = {{ color: themeColors.bg.start }} />,
        },
        {
          boldText: t('cards.01.benefits.bold03'),
          text:     t('cards.01.benefits.03'),
          icon:     <InsightsSharpIcon sx = {{ color: themeColors.bg.start }} />,
        },
        {
          boldText: t('cards.01.benefits.bold04'),
          text:     t('cards.01.benefits.04'),
          icon:     <ElectricRickshawSharpIcon sx = {{ color: themeColors.bg.start }} />,
        },
        {
          boldText: t('cards.01.benefits.bold05'),
          text:     t('cards.01.benefits.05'),
          icon:     <SettingsRemoteSharpIcon sx = {{ color: themeColors.bg.start }} />,
        },
        {
          boldText: t('cards.01.benefits.bold06'),
          text:     t('cards.01.benefits.06'),
          icon:     <WorkspacePremiumIcon sx = {{ color: themeColors.bg.start }} />,
        },
      ]}
      textColor = {themeColors.gray.mid}
    />
  )

  // *** MAIN RENDER ***
  return (
    <Box
      component = "section"
      sx = {{
        ...classes.section,
        gap:           screenDimensions.width <= breakpoints.desktopToMobile ? 0 : '16px',
        paddingTop:    getSectionMarginTop(),
        paddingBottom: getSectionMarginTop(),
        paddingLeft:   getSectionPadding(),
        paddingRight:  getSectionPadding(),
      }}
      gridTemplateColumns = "repeat(12, 1fr )"
    >
      {getSection()}
      {getSection2()}
    </Box>

  )
}
