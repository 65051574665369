// React, Next
import { useContext, useState, useCallback } from 'react'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { StaticImport } from 'next/dist/shared/lib/get-img-props'
import Image from 'next/image'
import debounce from 'lodash.debounce'

// MUI, reactstrap
import Box from '@mui/material/Box'
import { Typography } from '@mui/material'
import {
  DropdownItem,
} from 'reactstrap'

// Custom
import { GlobalContext } from 'context/GlobalContextProvider'
import { translateHref } from 'assets/functions/links'

// Style
import breakpoints from '@/assets/theme/breakpoints'
import themeColors from '@/assets/theme/colors'
import classes from './MainMenu-CSS'

type Props = {
  locale: string | string[]
}

export default function MainMenu(props:Props) {

  const { screenDimensions } = useContext(GlobalContext)

  const { t } = useTranslation('common')

  const [serviceHover, setServiceHover] = useState(false)
  const [productsHover, setProductsHover] = useState(false)
  const [softwareHover, setSoftwareHover] = useState(false)
  const [projectsHover, setProjectsHover] = useState(false)
  const [aboutHover, setAboutHover] = useState(false)
  const [contactHover, setContactHover] = useState(false)
  const [technologyHover, setTechnologyHover] = useState(false)

  const [engHover, setEngHover] = useState(false)
  const [softHover, setSoftHover] = useState(false)
  const [scygateHover, setScygateHover] = useState(false)
  const [dathascyHover, setDathascyHover] = useState(false)

  const setHover = (id:string, state:boolean) => {

    // if (id === 'eng') { setEngHover(state) }
    if (id === 'scygate') { setScygateHover(state) }

    // if (id === 'soft') { setSoftHover(state) }
    if (id === 'dathascy') { setDathascyHover(state) }
  }
  const getContactMarginRight = () => {
    if (screenDimensions.width > breakpoints.desktopMid)  return '100px'
    if (screenDimensions.width > breakpoints.desktopSmall)  return '50px'
    return '30px'
  }

  const debTime = 250
  const servicesUnhover = (state:boolean) => {
    setServiceHover(state)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }; const onServicesHover = useCallback(debounce(servicesUnhover, debTime), [])

  const productsUnhover = (state:boolean) => {
    setProductsHover(state)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }; const onProductsHover = useCallback(debounce(productsUnhover, debTime), [])

  const softwareUnhover = (state:boolean) => {
    setSoftwareHover(state)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }; const onSoftwareHover = useCallback(debounce(softwareUnhover, debTime), [])

  const getDropdownItem = (p:{
    id             : string,
    caption        : string,
    href           : string,
    img            : string|StaticImport,
    hovered        : boolean,
    objectPosition1: string
    objectPosition2: string
  }) => (
    <Link
      href  = {p.href}
      style = {classes.typoLink}
    >
      <DropdownItem
        style = {({ background: p.hovered ? themeColors.info.lightest : '#fff', ...classes.dropdownItemBox })}
        onMouseEnter = {() => setHover(p.id, true)}
        onMouseLeave = {() => setHover(p.id, false)}
      >
        <Box
          sx = {classes.imageBox}
        >
          <Image
            alt    = {p.caption}
            src    = {p.img}
            height = {100}
            width  = {100}
            style  = {{
              boxShadow:      'rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px',
              borderRadius:   '3px',
              opacity:        1,
              objectFit:      'cover',
              objectPosition: p.hovered ? p.objectPosition2 : p.objectPosition1,
              transition:     'object-position 500ms',
            }}
            priority = {false}
          />
        </Box>
        <Box
          sx    = {classes.typoBox}
          style = {p.hovered ? classes.itemHover : undefined}
        >
          <Typography
            sx = {classes.typoItem}
          >
            {p.caption}
          </Typography>
        </Box>

      </DropdownItem>
    </Link>
  )

  return (
    <>
      {/* <Box
        sx = {classes.linkBox}
        style = {{
          paddingLeft:  screenDimensions.width > breakpoints.desktopMid ? '40px' : '20px',
          paddingRight: screenDimensions.width > breakpoints.desktopMid ? '40px' : '20px',
        }}
        onMouseEnter = {() => onServicesHover(true)}
        onMouseLeave = {() => onServicesHover(false)}
      >
        <Box sx = {classes.upperBox}>
          <UncontrolledDropdown
            style        = {classes.dropdownClass}
            isOpen       = {serviceHover}

            // onMouseEnter = {() => setServicesDropdownOpen(true)}
            // onMouseLeave = {() => setServicesDropdownOpen(false)}
            // toggle       = {() => setServicesDropdownOpen(!servicesDropdownOpen)}
          >
            <DropdownToggle
              style      = {classes.dropdownToogle}
              tag     = "span"
              onClick = {(e) => e.preventDefault()}
            >
              <Typography sx = {classes.typoToogle}>
                {t('menu.services')}
              </Typography>
            </DropdownToggle>
            {
          !serviceHover ? null
            : (
              <DropdownMenu
                style = {classes.dropdownMenu}
                onMouseEnter = {() => onServicesHover(true)}
                onMouseLeave = {() => onServicesHover(false)}
              >
                <Box>
                  {
                    getDropdownItem({
                      id:              'eng',
                      caption:         t('menu.engineering'),
                      href:            translateHref(props.locale, 'engineering'),
                      img:             Engineering.src,
                      hovered:         engHover,
                      objectPosition1: '90%',
                      objectPosition2: '70%',
                    })
                  }
                </Box>
                <Box>
                  {
                    getDropdownItem({
                      id:              'soft',
                      caption:         t('menu.softwareDev'),
                      href:            translateHref(props.locale, 'software-development'),
                      img:             SoftwareDev,
                      hovered:         softHover,
                      objectPosition1: '80%',
                      objectPosition2: '50%',
                    })
                  }
                </Box>

              </DropdownMenu>
            )
        }
          </UncontrolledDropdown>
        </Box>
        <Box
          sx = {classes.underBox}
          style = {{
            opacity: serviceHover ? 1 : 0,
          }}
        />
      </Box>

      <Box
        sx = {classes.linkBox}
        style = {{
          paddingLeft:  screenDimensions.width > breakpoints.desktopMid ? '40px' : '20px',
          paddingRight: screenDimensions.width > breakpoints.desktopMid ? '40px' : '20px',
        }}
        onMouseEnter = {() => onProductsHover(true)}
        onMouseLeave = {() => onProductsHover(false)}
      >
        <Box sx = {classes.upperBox}>
          <UncontrolledDropdown
            style        = {classes.dropdownClass}
            isOpen       = {productsHover}
          >
            <DropdownToggle
              style      = {classes.dropdownToogle}
              tag     = "span"
              onClick = {(e) => e.preventDefault()}
            >
              <Typography sx = {classes.typoToogle}>
                {t('menu.products')}
              </Typography>
            </DropdownToggle>
            {
          !productsHover ? null
            : (
              <DropdownMenu
                style = {classes.dropdownMenu}
                onMouseEnter = {() => onProductsHover(true)}
                onMouseLeave = {() => onProductsHover(false)}
              >
                <Box>
                  {
                    getDropdownItem({
                      id:              'scygate',
                      caption:         t('menu.scygate'),
                      href:            translateHref(props.locale, 'scygate'),
                      img:             Disp.src,
                      hovered:         scygateHover,
                      objectPosition1: '90%',
                      objectPosition2: '70%',
                    })
                  }
                </Box>
              </DropdownMenu>
            )
        }
          </UncontrolledDropdown>
        </Box>
        <Box
          sx = {classes.underBox}
          style = {{
            opacity: productsHover ? 1 : 0,
          }}
        />
      </Box>

      <Box
        sx = {classes.linkBox}
        style = {{
          paddingLeft:  screenDimensions.width > breakpoints.desktopMid ? '40px' : '20px',
          paddingRight: screenDimensions.width > breakpoints.desktopMid ? '40px' : '20px',
        }}
        onMouseEnter = {() => onSoftwareHover(true)}
        onMouseLeave = {() => onSoftwareHover(false)}
      >
        <Box sx = {classes.upperBox}>
          <UncontrolledDropdown
            style        = {classes.dropdownClass}
            isOpen       = {softwareHover}
          >
            <DropdownToggle
              style      = {classes.dropdownToogle}
              tag     = "span"
              onClick = {(e) => e.preventDefault()}
            >
              <Typography sx = {classes.typoToogle}>
                {t('menu.software')}
              </Typography>
            </DropdownToggle>
            {
          !softwareHover ? null
            : (
              <DropdownMenu
                style = {classes.dropdownMenu}
                onMouseEnter = {() => onSoftwareHover(true)}
                onMouseLeave = {() => onSoftwareHover(false)}
              >
                <Box>
                  {
                    getDropdownItem({
                      id:              'dathascy',
                      caption:         t('menu.dathascy'),
                      href:            translateHref(props.locale, 'dathascy'),
                      img:             dathaScy1.src,
                      hovered:         dathascyHover,
                      objectPosition1: '90%',
                      objectPosition2: '70%',
                    })
                  }
                </Box>
              </DropdownMenu>
            )
        }
          </UncontrolledDropdown>
        </Box>
        <Box
          sx = {classes.underBox}
          style = {{
            opacity: softwareHover ? 1 : 0,
          }}
        />
      </Box>

      <Link
        href      = {translateHref(props.locale, 'projects')}
        style = {classes.typoLink}
      >
        <Box
          sx = {classes.linkBox}
          style = {{
            paddingLeft:  screenDimensions.width > breakpoints.desktopMid ? '40px' : '20px',
            paddingRight: screenDimensions.width > breakpoints.desktopMid ? '40px' : '20px',
          }}
          onMouseEnter = {() => setProjectsHover(true)}
          onMouseLeave = {() => setProjectsHover(false)}
        >
          <Box sx = {classes.upperBox}>
            {t('menu.projects')}
          </Box>
          <Box
            sx = {classes.underBox}
            style = {{
              opacity: !projectsHover ? 0 : 1,
            }}
          />
        </Box>
      </Link> */}
      <Link
        href  = {translateHref(props.locale, 'technology')}
        style = {classes.typoLink}
      >
        <Box
          sx    = {classes.linkBox}
          style = {{
            paddingLeft:  screenDimensions.width > breakpoints.desktopMid ? '40px' : '20px',
            paddingRight: screenDimensions.width > breakpoints.desktopMid ? '40px' : '20px',
          }}
          onMouseEnter = {() => setTechnologyHover(true)}
          onMouseLeave = {() => setTechnologyHover(false)}
        >
          <Box sx = {classes.upperBox}>
            {t('menu.technology')}
          </Box>
          <Box
            sx    = {classes.underBox}
            style = {{
              opacity: !technologyHover ? 0 : 1,
            }}
          />
        </Box>
      </Link>

      <Link
        href  = {translateHref(props.locale, 'services')}
        style = {classes.typoLink}
      >
        <Box
          sx    = {classes.linkBox}
          style = {{
            paddingLeft:  screenDimensions.width > breakpoints.desktopMid ? '40px' : '20px',
            paddingRight: screenDimensions.width > breakpoints.desktopMid ? '40px' : '20px',
          }}
          onMouseEnter = {() => setServiceHover(true)}
          onMouseLeave = {() => setServiceHover(false)}
        >
          <Box sx = {classes.upperBox}>
            {t('menu.services')}
          </Box>
          <Box
            sx    = {classes.underBox}
            style = {{
              opacity: !serviceHover ? 0 : 1,
            }}
          />
        </Box>
      </Link>

      <Link
        href  = {translateHref(props.locale, 'applications')}
        style = {classes.typoLink}
      >
        <Box
          sx    = {classes.linkBox}
          style = {{
            paddingLeft:  screenDimensions.width > breakpoints.desktopMid ? '40px' : '20px',
            paddingRight: screenDimensions.width > breakpoints.desktopMid ? '40px' : '20px',
          }}
          onMouseEnter = {() => setAboutHover(true)}
          onMouseLeave = {() => setAboutHover(false)}
        >
          <Box sx = {classes.upperBox}>
            {t('menu.applications')}
          </Box>
          <Box
            sx    = {classes.underBox}
            style = {{
              opacity: !aboutHover ? 0 : 1,
            }}
          />
        </Box>
      </Link>

      <Link
        href  = {translateHref(props.locale, 'contact')}
        style = {classes.typoLink}
      >
        <Box
          sx    = {classes.linkBox}
          style = {{
            paddingLeft:  screenDimensions.width > breakpoints.desktopMid ? '40px' : '20px',
            paddingRight: screenDimensions.width > breakpoints.desktopMid ? '40px' : '20px',
            marginRight:  getContactMarginRight(),
          }}
          onMouseEnter = {() => setContactHover(true)}
          onMouseLeave = {() => setContactHover(false)}
        >
          <Box sx = {classes.upperBox}>
            {t('menu.contact')}
          </Box>
          <Box
            sx = {classes.underBox}
            style = {{
              opacity: !contactHover ? 0 : 1,
            }}
          />
        </Box>
      </Link>
    </>
  )
}
