import themeColors from '@/assets/theme/colors'

const classes = {
  section: {
    display:        'grid',
    justifyContent: 'center',
    paddingTop:     '3rem',
    paddingBottom:  '5rem',
    paddingLeft:    '1.2rem',
    paddingRight:   '1.2rem',
    background:     themeColors.kroemtech.sensBlue,
    boxShadow:      'rgba(0, 0, 0, 0.15) 0px 3px 3px 0px',
  },
  typoTitle: {
    color:        themeColors.kroemtech.background,
    marginBottom: '30px',
    paddingLeft:  '10px',
    paddingRight: '10px',
    textAlign:    'center' as const,
    fontWeight:   400,
  },
  cardDiv: {
    display:        'flex',
    justifyContent: 'center',
    marginTop:      '1rem',
  },
  cardBox: {
    display: 'grid',
    zIndex:  2,
  },
  card: {
    display:      'flex',
    boxShadow:    'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
    borderRadius: '10px',
    background:   '#fff',
  },
  coloredBar: {
    maxWidth:     8,
    minWidth:     8,
    borderRadius: '10px 0 0 10px',
  },
  cardContentBox1: {
    display:        'flex',
    flexDirection:  'column',
    justifyContent: 'space-between',
  },
  cardContentBox2: {
    display:    'inline-block',
    alignItems: 'top',

  },
  typoH4: {
    color:      themeColors.gray.darker,
    fontWeight: 800,
    margin:     '15px',
  },
  typoB1: {
    color:  themeColors.gray.mid,
    margin: '15px',
  },
  learnMoreBox: {
    display:        'flex',
    justifyContent: 'right',
    marginTop:      '-10px',
  },
  learnMoreTypo: {
    color:  themeColors.gray.mid,
    margin: '15px',
  },
}

export default classes
