// React, Next
import { useTranslation } from 'next-i18next'
import { useContext } from 'react'

// MUI
import Box from '@mui/material/Box'
import VerifiedSharpIcon from '@mui/icons-material/VerifiedSharp'
import SaveAsRoundedIcon from '@mui/icons-material/SaveAsRounded'
import FolderZipRoundedIcon from '@mui/icons-material/FolderZipRounded'
import SecurityRoundedIcon from '@mui/icons-material/SecurityRounded'

// Custom
import { GlobalContext } from 'context/GlobalContextProvider'
import CardSection from '@/components/cards/cardSection/CardSection'
import CardSection2 from '@/components/cards/cardSection2/CardSection2'

// Img
import Data from 'public/img/services/server_w1000.webp'

// Style
import themeColors from 'assets/theme/colors'
import breakpoints from 'assets/theme/breakpoints'
import classes from './WarehousingSection-CSS'

export default function WarehousingSection() {

  const { t } = useTranslation('services')

  const { screenDimensions } = useContext(GlobalContext)

  const getSectionMarginTop = () => {
    const w = screenDimensions.width
    if (w > breakpoints.desktopLarge) return '6rem'
    if (w > breakpoints.desktopMid) return '4rem'
    if (w > breakpoints.desktopSmall) return '4rem'
    if (w > breakpoints.desktopToMobile) return '5rem'
    if (w > breakpoints.mobileMid) return '5rem'
    if (w > breakpoints.mobileSmall) return '5rem'
    return '5rem'
  }

  const getSectionPadding = () => {
    const w = screenDimensions.width
    if (w > breakpoints.desktopLarge) return '12rem'
    if (w > breakpoints.desktopMid) return '6rem'
    if (w > breakpoints.desktopToMobile) return '3rem'
    return '1rem'
  }

  // *** SECTION ***
  const getSection = () => (
    <CardSection
      title = {t('cards.03.title')}
      titleColor = {themeColors.bg.start}
      icon = {(
        <VerifiedSharpIcon sx = {{ color: themeColors.bg.start }} />
      )}
      text = {[
        t('cards.03.subtitle.01'),
        t('cards.03.subtitle.02'),
      ]}
      textColor = {themeColors.gray.mid}
      description = {t('cards.03.description')}
      image = {Data}
      imageAlt = "Kroemtech"
    />
  )
  const getSection2 = () => (
    <CardSection2
      title = {t('cards.benefitsText')}
      titleColor = {themeColors.bg.start}
      alignedTexts = {[
        {
          boldText: t('cards.03.benefits.bold01'),
          text:     t('cards.03.benefits.01'),
          icon:     <SaveAsRoundedIcon sx = {{ color: themeColors.bg.start }} />,
        },
        {
          boldText: t('cards.03.benefits.bold02'),
          text:     t('cards.03.benefits.02'),
          icon:     <FolderZipRoundedIcon sx = {{ color: themeColors.bg.start }} />,
        },
        {
          boldText: t('cards.03.benefits.bold03'),
          text:     t('cards.03.benefits.03'),
          icon:     <SecurityRoundedIcon sx = {{ color: themeColors.bg.start }} />,
        },
      ]}
      textColor = {themeColors.gray.mid}
    />
  )

  // *** MAIN RENDER ***
  return (
    <Box
      component = "section"
      sx = {{
        ...classes.section,
        gap:           screenDimensions.width <= breakpoints.desktopToMobile ? 0 : '3rem',
        paddingTop:    getSectionMarginTop(),
        paddingBottom: getSectionMarginTop(),
        paddingLeft:   getSectionPadding(),
        paddingRight:  getSectionPadding(),

      }}
      gridTemplateColumns = "repeat(12, 1fr )"
    >
      {getSection()}
      {getSection2()}
    </Box>

  )
}
