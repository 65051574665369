import { ReactElement } from 'react'
import CountryListAllIsoData from 'assets/lists/country_list'

export function GetCountryListWithFlags() {
  const list:{value: string, label: string|ReactElement}[] = [{ value: '', label: 'Select...' }]

  for (let i = 0; i < CountryListAllIsoData.length; i += 1) {
    list.push({
      value: `${CountryListAllIsoData[i].code}|${CountryListAllIsoData[i].name}`, // CH|Switzerland -> permits filtering by name and code at the same time
      label: (
        <div style = {{ display: 'flex', alignItems: 'center' }}>
          <img alt = "" src = {`https://flagcdn.com/w20/${CountryListAllIsoData[i].code.toLowerCase()}.png`} height = "20px" width = "20px" />
          <div className = "ml-2">
            {CountryListAllIsoData[i].name}
          </div>
        </div>
      ),
    })
  }

  return list
}

export function GetCountryPhonePrefixesWithFlags() {
  const list:{value: string, label: string|ReactElement}[] = [{ value: '', label: 'Select...' }]

  for (let i = 0; i < CountryListAllIsoData.length; i += 1) {
    if (CountryListAllIsoData[i].phone !== undefined) {
      list.push({
        value: `${CountryListAllIsoData[i].code}|${CountryListAllIsoData[i].phone}`, // CH|Switzerland -> permits filtering by name and code at the same time
        label: (
          <div style = {{ display: 'flex', alignItems: 'center' }}>
            <img alt = "" src = {`https://flagcdn.com/w20/${CountryListAllIsoData[i].code.toLowerCase()}.png`} height = "20px" width = "20px" />
            <div className = "ml-2">
              {CountryListAllIsoData[i].phone}
            </div>
          </div>
        ),
      })
    }
  }

  return list
}
