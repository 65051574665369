const classes = {
  section2: {
    gridColumn: 'span 12',
  },
  titleTypo: {
    marginTop:    '3rem',
    marginBottom: '2rem',
    marginLeft:   '1.6rem',
    textAlign:    'left',
    fontWeight:   400,
  },
  imgBox: {
    paddingBottom:  '2rem',
    display:        'flex',
    justifyContent: 'center',
  },
  img: {
    width:        400,
    height:       250,
    objectFit:    'cover' as const,
    borderRadius: '10px',
  },
  alignedText: {
    borderRadius: '5px',
    padding:      '10px 15px 10px 0',
  },
}

export default classes
