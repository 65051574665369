/* eslint-disable react/jsx-props-no-spreading */
// React, Next
import {
  ReactElement, useEffect, useRef, useState, RefObject,
} from 'react'
import { useTranslation } from 'next-i18next'

// Mui
import Box from '@mui/material/Box'
import FadeIn from 'react-fade-in'

// Types
import { SectionApplicationKey, SectionApplications } from '@/types/global_types'

// Sections
import GetStartedSection from '@/components/getStarted/GetStartedSection'
import WelcomeSection from './sections/WelcomeSection'
import GlobalSection from './sections/GlobalSection'
import IndustriesSection from './sections/IndustriesSection'
import { globalSectionProps } from './ApplicationsConstant'
import WhyIotSection from './sections/WhyIotSection'

// Styles
import classes from './ApplicationsView-CSS'

export default function ApplicationsView() {
  const { t } = useTranslation('applications')

  const sectionRefs: Record<SectionApplicationKey, RefObject<HTMLDivElement>> = {
    airQuality:        useRef<HTMLDivElement>(null),
    waterQuality:      useRef<HTMLDivElement>(null),
    workerSafety:      useRef<HTMLDivElement>(null),
    motorHealth:       useRef<HTMLDivElement>(null),
    energyConsumption: useRef<HTMLDivElement>(null),
    qualityControl:    useRef<HTMLDivElement>(null),
    leakDetection:     useRef<HTMLDivElement>(null),
    undergroundWater:  useRef<HTMLDivElement>(null),
    soil:              useRef<HTMLDivElement>(null),
    solarRadiation:    useRef<HTMLDivElement>(null),
    goodsTracking:     useRef<HTMLDivElement>(null),
    spoiledFood:       useRef<HTMLDivElement>(null),
    ripeningControl:   useRef<HTMLDivElement>(null),
    naturalCondition:  useRef<HTMLDivElement>(null),
    industries:        useRef<HTMLDivElement>(null),
    getStarted:        useRef<HTMLDivElement>(null),
  }

  const initialVisibility: Record<SectionApplicationKey, boolean> = {
    airQuality:        false,
    waterQuality:      false,
    workerSafety:      false,
    motorHealth:       false,
    energyConsumption: false,
    qualityControl:    false,
    leakDetection:     false,
    undergroundWater:  false,
    soil:              false,
    solarRadiation:    false,
    goodsTracking:     false,
    spoiledFood:       false,
    ripeningControl:   false,
    naturalCondition:  false,
    industries:        false,
    getStarted:        false,
  }

  const [visibility, setVisibility] = useState(initialVisibility)

  const updateVisibility = (key: string, isVisible: boolean) => {
    setVisibility((prev) => ({ ...prev, [key]: isVisible }))
  }

  useEffect(() => {
    const observers = (Object.keys(sectionRefs) as SectionApplicationKey[]).map((key) => {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => updateVisibility(key, entry.isIntersecting))
      })
      if (sectionRefs[key].current) {
        observer.observe(sectionRefs[key].current as Element)
      }
      return observer
    })

    return () => {
      observers.forEach((observer) => observer.disconnect())
    }
  }, [])

  const getSection = (p: {
    section  : ReactElement,
    reference: RefObject<HTMLDivElement>,
    visible  : boolean,
    key      : string
  }) => (
    <FadeIn
      delay              = {70}
      transitionDuration = {750}
      visible            = {p.visible}
      key                = {p.key}
    >
      <div ref = {p.reference}>
        {p.section}
      </div>
    </FadeIn>
  )

  const scrollToSection = (section: SectionApplicationKey) => {
    const element = sectionRefs[section]?.current
    if (element) {
      const appBarHeight = section !== 'industries' ? 50 : 120 // App bar height offset
      const elementPosition = element.getBoundingClientRect().top + window.pageYOffset
      const offsetPosition = elementPosition - appBarHeight

      window.scrollTo({
        top:      offsetPosition,
        behavior: 'smooth',
      })
    }
  }

  const cards: SectionApplications[] = [
    'airQuality',
    'waterQuality',
    'workerSafety',
    'leakDetection',
    'motorHealth',
    'qualityControl',
    'energyConsumption',
    'goodsTracking',
    'spoiledFood',
    'ripeningControl',
    'naturalCondition',
    'undergroundWater',
    'soil',
    'solarRadiation',
  ]

  return (
    <div>
      <Box style = {classes.main}>
        <WelcomeSection />

        <WhyIotSection />

        {getSection({
          section:   <IndustriesSection scrollToSection = {scrollToSection} />,
          reference: sectionRefs.industries,
          visible:   visibility.industries,
          key:       'industries',
        })}

        {
          cards.map((c, i) => getSection({
            section: <GlobalSection
              {...globalSectionProps[c]}
              cardNumber = {i}
              scrollTop  = {() => scrollToSection('industries')}
            />,
            reference: sectionRefs[c],
            visible:   visibility[c],
            key:       c,
          }))
        }

        {getSection({
          section: <GetStartedSection
            title    = {t('started.title')}
            subTitle = {t('started.subTitle')}
            button   = {t('started.button')}
          />,
          reference: sectionRefs.getStarted,
          visible:   visibility.getStarted,
          key:       'getStarted',
        })}
      </Box>
    </div>
  )
}
